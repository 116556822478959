import React, { useState } from "react";
import TitleProps from "../GeneralTemplate/TitleProps";
import FullStackInfo from "./FullStackInfo";
import ".././GeneralTemplate/GeneralTemplate.css";
import FullStack from "./FullStackImages/FullStack3.jpg";
import EnquiryModal from "../ModelBox/ModelBox";
import FooterMain from "../Footer/FooterMain";
import Header1 from "../Header/Header1";
import axios from "axios";
import { baseURL } from "../../http";
import SmallSnackBar from "../SmallSnackBar/SmallSnackBar";
import { customAlert } from "../SweetAlertCommon/Custom";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import NormalUseCaseLeft from "../GeneralTemplate/NormalUseCaseLeft";
import NormalUsecaseRight from "../GeneralTemplate/NormalUsecaseRight";
import GetInTouch from "../GeneralTemplate/GetInTouch";
import fullstack1 from "./FullStackUsecaseImages/HR.png";
import fullstack2 from "./FullStackUsecaseImages/AI.jpg";
import fullstack3 from "./FullStackUsecaseImages/full2.jpg";
import fullstack4 from "./FullStackUsecaseImages/full4.jpg";
import fullstack5 from "./FullStackUsecaseImages/JOB.jpg";
import fullstack6 from "./FullStackUsecaseImages/fs6.png";

const Title1 = [
  {
    imageSrc: FullStack,
    title: "FullStack",
    description:
      "Full Stack Developers excel in building robust web applications, integrating front-end and back-end technologies to deliver seamless user experiences.",
    buttonLabel: "Reach Out",
  },
];

const useCase1 = {
  imageSrc: fullstack1,
  header: "HR Management System (HRMS)",
  description:
    "HRMS allows companies to manage employee data, payroll, performance reviews, and leave requests. Full-stack developers build comprehensive systems that integrate HR workflows, such as employee onboarding, attendance tracking, and performance appraisals. These systems feature a user-friendly front-end for employees and a robust back-end to handle large amounts of sensitive data securely.",
};

const useCase2 = {
  imageSrc: fullstack2,
  header: "AI-Powered Question Generation System",
  description:
    "AI-powered question generation systems are used in educational platforms or interview preparation tools. Full-stack developers integrate natural language processing (NLP) models to generate questions based on user input or a given text. The front-end allows users to interact with the system and request specific question types, while the back-end manages the data processing, AI model integration, and database storage.",
};

const useCase3 = {
  imageSrc: fullstack3,
  header: "Online Learning and Training Platforms",
  description:
    " Full-stack development plays a crucial role in online learning platforms, which provide access to a wide variety of courses. Full-stack developers build responsive front-ends for course catalogs, video streaming, and discussion forums. The back-end manages user data, course enrollments, progress tracking, and certificates.",
};

const useCase4 = {
  imageSrc: fullstack4,
  header: " Internship Platforms",
  description:
    "Full-stack development powers internship platforms where students and recent graduates can search and apply for internships. These platforms connect employers with interns by offering a searchable database of internships, a user-friendly application process, and a dashboard for employers to manage applicants.",
};

const useCase5 = {
  imageSrc: fullstack5,
  header: "Job Application Platforms",
  description:
    "Full-stack developers build job application portals where candidates can apply for jobs, create profiles, and connect with employers. These platforms offer a front-end for job seekers to upload resumes, search for jobs, and track application statuses. The back-end manages user data, job postings, and secure communication between applicants and recruiters, ensuring smooth application processing and data storage.",
};
const useCase6 = {
  imageSrc: fullstack6,
  header: "Corporate Training Systems",
  description:
    "Full-stack developers create platforms for employee training and professional development. These systems allow companies to upload training modules, track employee progress, and assess performance. The front-end provides users with interactive training material (videos, quizzes, etc.), while the back-end tracks employee progress and manages certifications or reports for HR.",
};

const Section1Data = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallSnackBar />
      <Header1 />
      <div className="TitleProps-container">
        {Title1.map((Section1) => (
          <TitleProps
            key={Section1.title}
            imageSrc={Section1.imageSrc}
            title={Section1.title}
            description={Section1.description}
            buttonLabel={Section1.buttonLabel}
            onButtonClick={handleOpenModal}
          />
        ))}
        <FullStackInfo />
        <h1 className="use-case-heading">USE CASES</h1>
        <NormalUseCaseLeft {...useCase1} />
        <NormalUsecaseRight {...useCase2} />
        <NormalUseCaseLeft {...useCase3} />
        <NormalUsecaseRight {...useCase4} />
        <NormalUseCaseLeft {...useCase5} />
        <NormalUsecaseRight {...useCase6} />
      </div>
      <GetInTouch />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />

      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        errors={errors}
      />

      {loading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application" />
      )}
    </>
  );
};

export default Section1Data;
