import React from "react";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./Components.css";

function Components1({ Header, Description, Image }) {
  return (
    <div className="component-background-container1">
      <div className="component-width-container">
        <div className="component-main-container1">
          <div className="component-image-container">
            <img src={Image} alt="component" className="component-image" />
          </div>
          <div className="component-content-container">
            <h1>{Header}</h1>
            <p>{Description}</p>
            <div className="component-button">
              <Button endIcon={<ArrowForwardIcon />}>Read More</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Components1;
