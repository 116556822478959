import React from "react";
import Footer from "./FooterLandspace";

const FooterMain = () => {
  return (
    <div>
      <Footer />
    </div>
  );
};
export default FooterMain;
