import React from "react";
import "./Staffing.css";

function TestimonialCard({ text, person, role }) {
  return (
    <div className="testimonial-card">
      <p className="testimonial-text">"{text}"</p>
      <h4 className="testimonial-person">{person}</h4>
      <p className="testimonial-role">{role}</p>
    </div>
  );
}

export default TestimonialCard;
