import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import HeaderMain from "./components/Header/HeaderMain";
import CarouselMain from "./components/Carousel/CarouselMain";
import Footer from "./components/Footer/FooterMain";
import ComponentsMain from "./components/Components/ComponentsMain";
import MainPartners from "./components/OurPartners/MainPartners";
import Section1 from "./components/HeroSection/Section1";
import AutomativeCAD from "./components/AutomativeCAD/AutomativeCAD";
import FullStackSection from "./components/FullStackPage/FullStackSection";
import DigitalMarket from "./components/DigitalMarketing/DigitalMarket";
import ComputerVision from "./components/ComputerVision/ComputerVision";
import ContactUsMain from "./components/ContactUs/ContactUsMain";
import CareersMain from "./components/Careers/CareersMain";
import StaffingMain from "./components/Staffing/StaffingMain";
import AboutMain from "./components/AboutUs/AboutMain";
import CadAutomation from "./components/CadAutomation/CadAutomationSection";
import PlmSolution from "./components/PlmSolutions/PlmSolutionSection";
import ModelGrid from "./components/Model/ModelGrid";
import SuccessMain from "./components/SuccessStories/SuccessStory";
import ProcessProgressCircle from "./components/ProcessProgressCircle/ProcessProgressCircle";
import SEOComponent from "./components/SEO/SEOComponent";

function Main() {
  return (
    <Router>
      <SEOComponent url="https://happytechnovation.com/" />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/FooterMain" element={<Footer />}></Route>
        <Route path="/CarouselMain" element={<CarouselMain />}></Route>
        <Route path="/header" element={<HeaderMain />} />
        <Route path="/components" element={<ComponentsMain />} />
        <Route path="/FooterMain" element={<Footer />} />
        <Route path="/CarouselMain" element={<CarouselMain />} />
        <Route path="/components" element={<ComponentsMain />} />
        <Route path="/partners" element={<MainPartners />} />
        <Route path="/aiml" element={<Section1 />} />
        <Route path="/automotivedesign" element={<AutomativeCAD />} />
        <Route path="/fullstack" element={<FullStackSection />} />
        <Route path="/computervision" element={<ComputerVision />} />
        <Route path="digitalmarketing" element={<DigitalMarket />} />
        <Route path="/about" element={<AboutMain />} />
        <Route path="/cadautomation" element={<CadAutomation />} />
        <Route path="/contact" element={<ContactUsMain />} />
        <Route path="/careers" element={<CareersMain />} />
        <Route path="/staffing" element={<StaffingMain />} />
        <Route path="/plmsolution" element={<PlmSolution />} />
        <Route path="/model" element={<ModelGrid />} />
        <Route path="/success" element={<SuccessMain />} />
        <Route
          path="/processprogresscircle"
          element={<ProcessProgressCircle />}
        />
      </Routes>
    </Router>
  );
}

export default Main;
