import React, { useRef } from "react";
import "./PartnersSection.css";
import BMW from "./bmw.png";
import Ford from "./Ford.png";
import Tata from "./Tata.png";
import Volvo from "./Volvo.png";
import Mahindra from "./Mahindra.png";
import Airbus from "./Airbus.png";
import Boeing from "./Boeing.png";
import itc from "./ITC.png";

function PartnersSection() {
  const slideRef = useRef(null);

  return (
    <div className="background_container">
      <div className="company-logo-container">
        {/* Heading */}
        <h2 className="company-logo-heading">OUR PARTNERS</h2>

        {/* Company Logos */}
        <div className="logos-slide-container" ref={slideRef}>
          <div className="logos-slides">
            <img src={BMW} alt="BMW" />
            <img src={Ford} alt="Ford" />
            <img src={Tata} alt="Tata" />
            <img src={Volvo} alt="Volvo" />
            <img src={itc} alt="itc" />
            <img src={Mahindra} alt="Mahindra" />
            <img src={Airbus} alt="Airbus" />
            <img src={Boeing} alt="Boeing" />

            {/* Duplicate logos for smooth infinite scroll */}
            <img src={BMW} alt="BMW" />
            <img src={Ford} alt="Ford" />
            <img src={Tata} alt="Tata" />
            <img src={Volvo} alt="Volvo" />
            <img src={itc} alt="itc" />
            <img src={Mahindra} alt="Mahindra" />
            <img src={Airbus} alt="Airbus" />
            <img src={Boeing} alt="Boeing" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersSection;
