import React, { useState } from "react";
import "./Staffing.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TestimonialCard from "./TestimonialCard";

function Testinomials() {
  const testimonials = [
    {
      text: "Great service! Our project was completed ahead of schedule, thanks to Happy Technovation.",
      person: "Alice Brown",
      role: "Project Lead, Web Solutions",
    },
    {
      text: "The team's expertise made our transition seamless. Highly recommend!",
      person: "Bob White",
      role: "CTO, NextGen Technologies",
    },
    {
      text: "Exceptional support and quick turnaround times. A reliable partner!",
      person: "Charlie Green",
      role: "VP, Cloud Services",
    },
    {
      text: "Happy Technovation exceeded our expectations with their innovative solutions.",
      person: "Diana Black",
      role: "Founder, StartUp Co.",
    },
    {
      text: "The collaboration was smooth, and the results were impressive.",
      person: "Eve Gray",
      role: "Product Manager, Future Tech",
    },
    {
      text: "Efficient and effective service. They really understand our needs.",
      person: "Frank Blue",
      role: "Operations Head, Digital Innovations",
    },
    {
      text: "Reliable, professional, and always deliver on time. Thank you!",
      person: "Grace Yellow",
      role: "Marketing Director, Growth Solutions",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? testimonials.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === testimonials.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <section className="staffing-testimonials">
      <h2 className="staffing-testimonials-heading">Testimonials</h2>
      <div className="testimonial-slider-container">
        <button className="testimonial-nav left" onClick={goToPrevious}>
          <ArrowBackIosIcon />
        </button>

        <div className="testimonial-slider">
          <TestimonialCard
            text={testimonials[currentIndex].text}
            person={testimonials[currentIndex].person}
            role={testimonials[currentIndex].role}
          />
        </div>

        <button className="testimonial-nav right" onClick={goToNext}>
          <ArrowForwardIosIcon />
        </button>
      </div>
    </section>
  );
}

export default Testinomials;
