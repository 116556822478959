import React from "react";
import "./GeneralTemplate.css";

const Section1_Data = ({
  imageSrc,
  title,
  description,
  buttonLabel,
  onButtonClick,
}) => {
  return (
    <section className="TitleProps_section1">
      <img src={imageSrc} alt=" " className="TitleProps_section1-image" />
      <div className="TitleProps_section1-content">
        <h1>{title}</h1>
        <p>{description}</p>
        <button onClick={onButtonClick}>{buttonLabel}</button>
      </div>
    </section>
  );
};
export default Section1_Data;
