import React, { useState } from "react";
import axios from "axios";
import "./Careers.css";
import CareerCards from "./CareerCards";
import ApplyModal from "./ApplyModal";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import careers from "./CareersImages/career.jpg";
import Careers_recruit from "./CareersImages/careers_recruit.png";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";

const Careers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    Phone: "",
    resume: null,
    services: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (value, country) => {
    let updatedPhoneNumber = value;

    if (country.dialCode !== "91") {
      updatedPhoneNumber = `+${country.dialCode} ${value.slice(
        country.dialCode.length
      )}`;
    }

    handleChange({
      target: {
        name: "Phone",
        value: updatedPhoneNumber,
      },
    });
  };

  const handleApplyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      Phone: "",
      resume: null,
      services: "",
      message: "",
    });
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        resume: file,
        resumeName: file.name,
      });
    }
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    const data = new FormData();
    data.append("Name", formData.name);
    data.append("Email", formData.email);
    data.append("Phone", formData.Phone);
    data.append("Resume", formData.resume);
    data.append("ResumeName", formData.resumeName);
    data.append("Services", formData.services);
    data.append("Message", formData.message);

    try {
      const response = await axios.post(`${baseURL}/careers_post`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      customAlert("", "Job application submitted successfully", "success");
      setFormData({
        name: "",
        email: "",
        Phone: "",
        resume: null,
        message: "",
      });
    } catch (error) {
      customAlert("", "Failed to submit the job application", "error");
    }

    setLoading(false);
    setIsModalOpen(false);
  };

  return (
    <>
      {loading && <ProcessProgressCircle RequiredProgressName="Submitting" />}

      <div className="careers-banner">
        <div className="image-container">
          <img src={careers} alt="Career Banner" className="banner-image" />
          <div className="careers-text-wrapper">
            <div className="banner-text">
              <h1>
                Build a fulfilling career with us, where talented minds come
                together to shape the future.
              </h1>
              <h2 className="apply-now" onClick={handleApplyClick}>
                Apply Now <span className="arrow">→</span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <ApplyModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleFileChange={handleFileChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
      />

      <div className="career-section">
        <div className="career-content">
          <div className="career-text">
            <h1>RECRUITMENT</h1>
            <p>
              Our recruitment process is designed to identify adaptable,
              flexible, and exceptional individuals who share our vision and
              values.
            </p>
            <p>
              We believe in nurturing fresh talent and providing opportunities
              for young graduates to kick-start their careers through our campus
              and off-campus recruitment initiatives.
            </p>
            <p>
              For experienced professionals, we leverage various channels to
              find the best fit, including job portals, employee referrals,
              social media, and strategic partnerships with industry
              associations and event organizers.
            </p>
          </div>
          <div className="career-image">
            <img src={Careers_recruit} alt="Career Opportunities" />
          </div>
        </div>
      </div>
      <CareerCards />
    </>
  );
};

export default Careers;
