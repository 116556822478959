import React from "react";
import Header1 from "./Header1";

const HeaderMain = () => {
  return (
    <div>
      <Header1 />
    </div>
  );
};

export default HeaderMain;
