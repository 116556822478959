import React from "react";
import "./LandStaffing.css";
import StaffingIcon from "./StaffingImages/Temporary.jpg";

function LandStaffing() {
  return (
    <div className="Land-staffing-page-outer">
      <div className="Land-staffing-page-container">
        <div className="Land-left-section">
          <h2>
            Unlock Your Team's Potential with Our Expert Staffing Services
          </h2>
          <p>
            At HTE Services, we understand that finding the right talent is
            crucial to driving business success. Our staffing solutions bridge
            the gap between talented professionals and organizations seeking
            exceptional talent. With years of experience and a deep
            understanding of the industry, we provide tailored staffing services
            to meet your unique needs.
          </p>
          <a href="staffing" className="Land-staff-button">
            EXPLORE MORE
          </a>
        </div>

        <div className="Land-right-section">
          <div className="Land-sticky-container">
            <div className="Land-section-content active">
              <img
                src={StaffingIcon}
                alt="Staffing Icon"
                className="Land-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandStaffing;
