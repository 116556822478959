import { useEffect } from "react";
import './App.css';
import Main from './Main.js';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Main/>
    </div>
  );
}

export default App;
