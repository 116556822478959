import React from "react";
import "./CadAutomation.css";

const CadServices = () => {
  const logos = [
    require("./CadAutomationImages/logo21.png"),
    require("./CadAutomationImages/logo31.png"),
    require("./CadAutomationImages/logo41.png"),
    require("./CadAutomationImages/logo5.webp"),
  ];

  return (
    <div className="autoservicesmain-container">
      <div className="autoservices-container">
        <div className="autoservices-content">
          <p>
            We provide step by step interactive solution from basic programming
            to professional Add-in / Plug-in & Applications development. 
          </p>
          <p>
            Our Services includes Automation & customization of various CAD
            tools like CATIA V5 and 3DX, NX, CREO, Solid works, Solid Edge,
            AutoCAD.
          </p>
          <p>
            Automate & customize using different programming languages like C++
            , CAA, C#, NET, VB.NET, JAVA & PYTHON.
          </p>
        </div>
        <div className="autoservices-logo-container">
          {logos.map((logo, index) => (
            <div className="autoservices-logo" key={index}>
              <img src={logo} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CadServices;
