import React from "react";
import ComponentsSection from "./ComponentsSection";

const ComponentsMain = () => {
  return (
    <div>
      <ComponentsSection />
    </div>
  );
};

export default ComponentsMain;
