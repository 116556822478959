import React from "react";
import Header1 from "./../Header/Header1";
import AboutUs from "./AboutUs";
import FooterMain from "./../Footer/FooterMain";
import SmallSnackBar from "./../SmallSnackBar/SmallSnackBar";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";

function AboutMain() {
  return (
    <div>
      <SmallSnackBar />
      <Header1 />
      <AboutUs />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />
    </div>
  );
}

export default AboutMain;
