import React, { useEffect, useRef, useState } from "react";
import "./LandingUseCase.css";
import AutomotiveCAD from "../LandingPageImages/Automotive.png";

function AutomotiveCad() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="automotive-cad-split-outer">
      <div
        ref={sectionRef}
        className={`automotive-cad-split-screen ${
          isVisible ? "fade-slide-in" : ""
        }`}
      >
        {/* Right Side: Image */}
        <div className="automotive-cad-right">
          <img src={AutomotiveCAD} alt="Automotive CAD" />
        </div>
        {/* Left Side: Content */}
        <div className="automotive-cad-left">
          <div className="automotive-cad-content">
            <h1>
              Innovating Automotive Design: CAD Solutions for the Future of
              Mobility
            </h1>
            <p>
              Our Automotive CAD solutions enable manufacturers to create highly
              efficient and precise designs for modern vehicles. From
              prototyping to production, our automation tools ensure accuracy
              and speed.
            </p>
            <ul>
              <li>3D Vehicle Modeling</li>
              <li>Automated Component Assembly</li>
              <li>CAD Customization for Automotive Standards</li>
              <li>Integration with Automotive Engineering Tools</li>
            </ul>

            {/* Button for navigation */}
            <a href="/automotivedesign" className="automotive-cad-button">
              EXPLORE MORE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutomotiveCad;
