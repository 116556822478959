import React, { useState } from "react";
import axios from "axios";
import EnquiryModal from "../ModelBox/ModelBox";
import { baseURL } from "../../http";
import TitleProps from "../GeneralTemplate/TitleProps";
import ComputerVissionDescription from "./ComputerVissionInfo";
import Computervision from "./ComputerVisionImages/Cv1.jpg";
import UseCaseLeft from "../GeneralTemplate/UseCaseLeft";
import UseCaseRight from "../GeneralTemplate/UseCaseRight";
import Header1 from "../Header/Header1";
import FooterMain from "../Footer/FooterMain";
import SmallSnackBar from "../SmallSnackBar/SmallSnackBar";
import { customAlert } from "../SweetAlertCommon/Custom";
import AutomatedGap from "./CVUsecaseImages/Automated_Gap.png";
import MarkSymbol from "./CVUsecaseImages/Mark_Symbol_Detection.png";
import ObjectDetection from "./CVUsecaseImages/objectDetection .png";
import PDF_drawing from "./CVUsecaseImages/PDF Drawing.png";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import GetInTouch from "../GeneralTemplate/GetInTouch";

const Title5 = [
  {
    imageSrc: Computervision,
    title: "Computer Vision",
    description:
      "Computer Vision empowers machines to interpret and understand visual data, enhancing automation, analytics, and user experiences across various industries.",
    buttonLabel: "Reach Out",
  },
];

const useCaseProps1 = {
  imageSrc: AutomatedGap,
  heading: "Automated Gap and Dimension Checking with Computer Vision",
  problemStatement:
    "<strong>Problem Statement:</strong> Manual inspection of gaps and dimensions from images is slow and error-prone, affecting product quality and accuracy",
  solution:
    "<strong>Solution:</strong> Use computer vision to automatically measure gaps and dimensions from images. This will speed up the quality control process and improve measurement accuracy for all units.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Speeds up quality checks, enhances accuracy, and ensures consistent product quality.",
};

const useCaseProps2 = {
  imageSrc: MarkSymbol,
  heading: "Mark and Symbol Detection in PDF Drawing",
  problemStatement:
    "<strong>Problem Statement:</strong> Manually detecting and interpreting marks and symbols in PDF drawings is labor-intensive and prone to errors",
  solution:
    "<strong>Solution:</strong>  Use Computer Vision to automatically detect and interpret marks and symbols in PDF drawings.",
  efficiency:
    "<strong>Efficiency Improvement:</strong>  Speeds up the detection process by up to 80% and improves accuracy, reducing manual review time.",
};

const useCaseProps3 = {
  imageSrc: ObjectDetection,
  heading: "Feature/ object Detection in 3D CAD Model",
  problemStatement:
    "<strong>Problem Statement:</strong> Detecting specific features or objects in 3D CAD models is manual and prone to errors",
  solution:
    "<strong>Solution:</strong> Use Computer Vision to automatically detect features or objects in 3D CAD models. Streamlines the identification process.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Reduces detection time and improves accuracy by up to 65%.",
};

const useCaseProps4 = {
  imageSrc: PDF_drawing,
  heading: "AI/ ML  -PDF Drawing and 3D CAD Compare",
  problemStatement:
    "<strong>Problem Statement:</strong> Comparing PDF drawings with 3D CAD models manually is slow and error-prone.",
  solution:
    "<strong>Solution:</strong> Use AI/ML to automatically compare PDF drawings with 3D CAD models. Identifies discrepancies quickly and accurately.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Speeds up comparison and increases accuracy by up to 70%.",
};

const Section1Data = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallSnackBar />
      <Header1 />
      <div className="TitleProps-container">
        {Title5.map((Section1) => (
          <TitleProps
            key={Section1.title}
            imageSrc={Section1.imageSrc}
            title={Section1.title}
            description={Section1.description}
            buttonLabel={Section1.buttonLabel}
            onButtonClick={handleOpenModal}
          />
        ))}
        <ComputerVissionDescription />
        <h1 className="use-case-heading">USE CASES</h1>
        <div className="card-container">
          <UseCaseLeft {...useCaseProps1} />
          <UseCaseRight {...useCaseProps2} />
          <UseCaseLeft {...useCaseProps3} />
          <UseCaseRight {...useCaseProps4} />
        </div>
      </div>
      <GetInTouch />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />

      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        errors={errors}
      />

      {loading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application" />
      )}
    </>
  );
};

export default Section1Data;
