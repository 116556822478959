import React from "react";
import "./NormalUsecase.css";

function NormalUseCaseLeft({ imageSrc, header, description }) {
  return (
    <>
      <div className="Normal-left-use-case-outer">
        <div className="Normal-left-use-case-container">
          <div className="Normal-left-use-case-image">
            <img src={imageSrc} alt="Use Case" />
          </div>
          <div className="Normal-left-use-case-content">
            <h2>{header}</h2>
            <p>{description}</p>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
    </>
  );
}

export default NormalUseCaseLeft;
