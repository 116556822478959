import React, { useState } from "react";
import "./Marketing.css";

const DigitalDescription = () => {
  const [heading, setHeading] = useState("Digital Marketing");
  const [description, setDescription] = useState(
    "Digital marketing is essential for businesses aiming to thrive in today's online landscape. It encompasses various strategies, including search engine optimization (SEO), content marketing, social media marketing, email campaigns, and pay-per-click advertising. By leveraging these tools, companies can reach their target audience effectively and engage with them on multiple platforms. Digital marketing not only enhances brand visibility but also drives traffic to websites, increases conversions, and fosters customer loyalty. With the ability to analyze data in real time, businesses can adapt their strategies for optimal performance. Investing in digital marketing is crucial for achieving sustainable growth and staying competitive in the market."
  );

  return (
    <div className="DigitalMarketing-background-container">
      <section className="DigitalMarketing_section2">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default DigitalDescription;
