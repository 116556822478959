import React, { useState } from "react";
import "./AutomativeCAD.css";

const AutomativeCADDescription = () => {
  const [heading, setHeading] = useState("Automotive Design");
  const [description, setDescription] = useState(
    "Our automotive design philosophy emphasizes the harmonious blend of cutting-edge technology and elegant aesthetics, ensuring that each vehicle not only delivers exceptional performance and safety but also enhances the overall driving experience while adhering to sustainable practices. We prioritize user-centric features and innovative solutions, allowing for seamless connectivity and comfort tailored to modern lifestyles. Through collaboration with industry experts and a commitment to continuous improvement, we strive to set new standards in automotive excellence."
  );

  return (
    <div className="AutomativeCAD-background-container">
      <section className="AutomativeCAD_section2">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default AutomativeCADDescription;
