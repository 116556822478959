import React, { useState, useEffect, useRef } from "react";
import "./Header1.css";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";

function Header1() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const menuRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <div className={`outer-container ${isSticky ? "sticky" : ""}`}>
      <div className="inner-container">
        <header className="header1" ref={menuRef}>
          <div className="logo">
            <a href="/">
              <img src="HT_LOGO_1.png" alt="logo" />
            </a>
          </div>
          <nav className={`nav-menu ${isMobileMenuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <a href="/">HOME</a>
              </li>
              <li>
                <a href="/staffing">STAFFING</a>
              </li>
              <li className="dropdown">
                <a href="#services">
                  SERVICES
                  <ExpandMoreSharpIcon className="dropdown-icon" />
                </a>
                <ul className="dropdown-content">
                  <li>
                    <a href="/automotivedesign">Automotive Design</a>
                  </li>
                  <li>
                    <a href="/aiml">AI/ML</a>
                  </li>
                  <li>
                    <a href="/cadautomation">
                      CAD Automation and Customization
                    </a>
                  </li>
                  <li>
                    <a href="/computervision">Computer Vision</a>
                  </li>
                  <li>
                    <a href="/digitalmarketing">Digital Marketing</a>
                  </li>
                  <li>
                    <a href="/fullstack">Full Stack</a>
                  </li>
                  <li>
                    <a href="/plmsolution">PLM Solutions</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/about">ABOUT US</a>
              </li>
              <li>
                <a href="/careers">CAREERS</a>
              </li>
              <li>
                <a href="/contact">CONTACT</a>
              </li>
            </ul>
          </nav>
          <div
            className={`hamburger ${isMobileMenuOpen ? "open" : ""}`}
            onClick={toggleMobileMenu}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header1;
