import React, { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ApplyModal from "./ApplyModal";
import "./Careers.css";
import { baseURL } from "../../http";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CareerCards = () => {
  const scrollRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    Phone: "",
    resume: null,
    resumeName: "",
    services: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const courseData = [
    {
      title: "Full Stack Development",
      description:
        "Design, develop, and deploy scalable web applications using cutting-edge technologies like React, Angular, and Node.js.",
    },
    {
      title: "AI/ML",
      description:
        "Unlock the potential of Artificial Intelligence and Machine Learning to drive business innovation, leveraging frameworks like TensorFlow and PyTorch.",
    },
    {
      title: "CAD Automation",
      description:
        "Streamline product design and development using automated CAD tools, integrating CAD software with programming languages like Python and C++.",
    },
    {
      title: "PLM Solutions",
      description:
        "Implement efficient Product Lifecycle Management solutions, integrating data management, collaboration, and analytics for seamless product development.",
    },
    {
      title: "Automotive Design",
      description:
        "Expertise in designing and developing precise automotive CAD models, leveraging software like CATIA, SolidWorks, and Autodesk Inventor.",
    },
    {
      title: "Computer Vision",
      description:
        "Develop intelligent systems that interpret and understand visual data from images and videos, using OpenCV, TensorFlow, and other CV libraries.",
    },
    {
      title: "Digital Marketing",
      description:
        "Craft and execute strategic digital marketing campaigns, leveraging SEO, social media, and analytics to drive business growth and engagement.",
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      Phone: "",
      resume: null,
      resumeName: "",
      services: "",
      message: "",
    });
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value, country) => {
    let updatedPhoneNumber = value;

    if (country.dialCode !== "91") {
      updatedPhoneNumber = `+${country.dialCode} ${value.slice(
        country.dialCode.length
      )}`;
    }

    handleChange({
      target: {
        name: "Phone",
        value: updatedPhoneNumber,
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        resume: file,
        resumeName: file.name,
      });
    }
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);

    const data = new FormData();
    data.append("Name", formData.name);
    data.append("Email", formData.email);
    data.append("Phone", formData.Phone);
    data.append("Resume", formData.resume);
    data.append("ResumeName", formData.resumeName);
    data.append("Services", formData.services);
    data.append("Message", formData.message);

    try {
      const response = await axios.post(`${baseURL}/careers_post`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      customAlert("", "Job application submitted successfully", "success");
      setFormData({
        name: "",
        email: "",
        Phone: "",
        resume: null,
        resumeName: "",
        services: "",
        message: "",
      });
    } catch (error) {
      customAlert("", "Failed to submit the job application", "error");
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1100, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 766, min: 0 }, items: 1 },
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        className="career-custom-arrow career-left-arrow"
        onClick={onClick}
      >
        <ArrowBackIosIcon />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="career-custom-arrow career-right-arrow"
        onClick={onClick}
      >
        <ArrowForwardIosIcon />
      </button>
    );
  };

  return (
    <div className="course-section">
      <h1 className="section-title">Ongoing Positions</h1>

      {isLoading && <ProcessProgressCircle RequiredProgressName="Submitting" />}

      {!isLoading && (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          keyBoardControl={true}
          customTransition="all 0.5s"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {courseData.map((course, index) => (
            <div key={index} className="course-card" onClick={handleOpenModal}>
              <h3>{course.title}</h3>
              <p>{course.description}</p>
              <div className="apply-now-container">
                <Button variant="outlined" className="career-apply-now-btn">
                  Apply Now
                </Button>
              </div>
            </div>
          ))}
        </Carousel>
      )}

      {!isLoading && (
        <ApplyModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          formData={formData}
          handleChange={handleChange}
          handlePhoneChange={handlePhoneChange}
          handleFileChange={handleFileChange}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default CareerCards;
