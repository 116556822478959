import React, { useState } from "react";
import TitleProps from "../GeneralTemplate/TitleProps";
import PlmSolutionInfo from "./PlmSolutionInfo";
import ".././GeneralTemplate/GeneralTemplate.css";
import PlmSolution from "./PLM12.jpg";
import Header1 from "./../Header/Header1";
import FooterMain from "./../Footer/FooterMain";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../http";
import SmallSnackBar from "../SmallSnackBar/SmallSnackBar";
import NormalUseCaseLeft from "../GeneralTemplate/NormalUseCaseLeft";
import NormalUsecaseRight from "../GeneralTemplate/NormalUsecaseRight";
import WindChill from "./PlmUsecaseImages/WindChill.png";
import PLMWind from "./PlmUsecaseImages/PLMWind.png";
import Migration1 from "./PlmUsecaseImages/Migration1.png";
import Adaptation from "./PlmUsecaseImages/Adaptation.png";
import DevOps from "./PlmUsecaseImages/DevOps.png";
import ERP from "./PlmUsecaseImages/ERP.png";
import Tailoring from "./PlmUsecaseImages/Tailoring.png";
import AdminServices from "./PlmUsecaseImages/WindChill Admin.png";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import GetInTouch from "../GeneralTemplate/GetInTouch";
import PLMLayout from "./PlmServies";
import EnquiryModal from "../ModelBox/ModelBox";

const Title7 = [
  {
    imageSrc: PlmSolution,
    title: "PLM Solutions",
    description:
      "Our PLM Solutions streamline product development, enhance collaboration, and improve efficiency, enabling businesses to bring innovative products to market faster.",
    buttonLabel: "Reach Out",
  },
];

const useCase1 = {
  imageSrc: Migration1,
  header: "Data Migration",
  description:
    "Data Migration in PLM solutions involves transferring data from legacy systems or other sources into the new PLM system. It ensures that all relevant product information, including design files, documents, and metadata, is accurately transferred, enabling a seamless transition and maintaining data integrity throughout the lifecycle.",
};

const useCase2 = {
  imageSrc: DevOps,
  header: "Windchill DEVOPS",
  description:
    "Windchill DEVOPS refers to applying DevOps practices to the management and deployment of Windchill, a PLM software. It involves automating tasks like software updates, configuration management, and continuous integration/continuous deployment (CI/CD) for Windchill, enhancing system reliability, reducing downtime, and streamlining development and operational workflows.",
};

const useCase3 = {
  imageSrc: Tailoring,
  header: "Application Tailoring",
  description:
    "Application Tailoring in PLM solutions involves customizing the PLM software to meet specific business requirements. This includes configuring the user interface, workflows, and data models to align with unique processes or industry standards, ensuring that the PLM system supports the organization's specific needs while enhancing user productivity and system efficiency.",
};

const useCase4 = {
  imageSrc: ERP,
  header: "Integrations",
  description:
    "Integrations in PLM solutions involve connecting the PLM system with other enterprise software, such as ERP, CAD, or CRM systems. This enables seamless data exchange across different platforms, improving collaboration, data consistency, and process automation, and ensuring that all teams have access to up-to-date product information throughout the product lifecycle.",
};

const useCase5 = {
  imageSrc: AdminServices,
  header: "Windchill Admin Services",
  description:
    "Windchill Admin Services involve managing and maintaining the Windchill PLM system to ensure its optimal performance. This includes tasks like user management, system configuration, troubleshooting, upgrades, and performance monitoring, which help keep the system secure, up-to-date, and aligned with the organization's evolving business needs.",
};

const useCase6 = {
  imageSrc: Adaptation,
  header: "Adaptation & Training services",
  description:
    "Adaptation & Training Services in PLM solutions focus on helping users and organizations get the most out of the PLM system. Adaptation services involve configuring the system to fit the company’s processes, while training services ensure that users understand how to use the PLM tools effectively, leading to smoother adoption, increased productivity, and maximized return on investment.",
};

const useCase7 = {
  imageSrc: WindChill,
  header: "PDM Link Classification Capabilities",
  description:
    "PDM Link Classification Capabilities in PLM solutions enable the organization and categorization of product data using predefined attributes and classification structures. This helps users easily find and manage parts, documents, and other assets by grouping them into logical categories, improving searchability, data consistency, and reuse of existing components across different projects.",
};

const useCase8 = {
  imageSrc: PLMWind,
  header: "Calculated Attributes Capabilities",
  description:
    "Calculated Attributes Capabilities in PLM solutions allow for the automatic calculation of attribute values based on defined formulas or business rules. These attributes can derive values from other data fields, such as dimensions, costs, or dates, enabling dynamic updates, reducing manual data entry, and ensuring that information stays accurate and up-to-date across the product lifecycle.",
};

const Section1Data = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallSnackBar />
      <Header1 />
      <div className="TitleProps-container">
        {Title7.map((Section1) => (
          <TitleProps
            key={Section1.title}
            imageSrc={Section1.imageSrc}
            title={Section1.title}
            description={Section1.description}
            buttonLabel={Section1.buttonLabel}
            onButtonClick={handleOpenModal}
          />
        ))}
        <PlmSolutionInfo />
        <PLMLayout />
        <h1 className="use-case-heading">USE CASES</h1>
        <NormalUseCaseLeft {...useCase1} />
        <NormalUsecaseRight {...useCase2} />
        <NormalUseCaseLeft {...useCase3} />
        <NormalUsecaseRight {...useCase4} />
        <NormalUseCaseLeft {...useCase5} />
        <NormalUsecaseRight {...useCase6} />
        <NormalUseCaseLeft {...useCase7} />
        <NormalUsecaseRight {...useCase8} />
      </div>
      <GetInTouch />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />

      {loading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application" />
      )}

      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        errors={errors}
      />
    </>
  );
};

export default Section1Data;
