import React from "react";
import Components1 from "./Components1";
import Components2 from "./Components2";
import fullstack from "./ComponentsImages/fullstack.jpg";
import automativecad from "./ComponentsImages/automativecad.jpg";
import aiml from "./ComponentsImages/aiml.jpg";
import computervision from "./ComponentsImages/computervision.jpg";
import digitalmarketing from "./ComponentsImages/digitalmarketing.jpg";
import CadAutomation from "./ComponentsImages/cad.jpg";
import plmsolutions from "./ComponentsImages/PLM.jpg";

function ComponentsSection() {
  return (
    <>
      <Components1
        Image={fullstack}
        Header="Full Stack"
        Description="Explore the dynamic world of full stack development, where frontend and backend technologies merge to create cutting-edge applications. Learn key programming languages like JavaScript, Python, and Ruby, and understand how to manage databases and servers. As a full stack developer, you’ll master both client-side and server-side technologies to become a versatile coder capable of delivering end-to-end web solutions."
      />
      <Components2
        Image={automativecad}
        Header="Automative CAD"
        Description="Master the essential tools and techniques for automotive design using CAD software. This component focuses on creating intricate designs for vehicles and their components, utilizing industry-standard software such as CATIA and SolidWorks. You'll learn to optimize designs for performance, efficiency, and safety, bringing your ideas from concept to production in the highly competitive automotive industry."
      />
      <Components1
        Image={aiml}
        Header="Artificial Intelligence and Machine Learning"
        Description="Explore AI and ML, the technologies driving automation. Learn how machines mimic human intelligence through predictive analytics and neural networks. Discover practical applications like chatbots, recommendation systems, and self-driving vehicles, while also addressing the ethical aspects of AI, laying the groundwork for advanced machine learning projects."
      />
      <Components2
        Image={computervision}
        Header="Computer Vision"
        Description="Learn how machines interpret and understand visual information using computer vision techniques. This field focuses on training systems to recognize patterns in images and videos, enabling applications like facial recognition, medical image analysis, and autonomous vehicle navigation. You'll gain expertise in OpenCV, TensorFlow, and other tools, making you proficient in the growing domain of machine vision."
      />
      <Components1
        Image={digitalmarketing}
        Header="Digital Marketing"
        Description="Get ahead in digital marketing by understanding key strategies for online branding and sales. This component covers SEO, social media marketing, email marketing, and content marketing to help you reach target audiences effectively. Additionally, you'll dive into data-driven marketing analytics to refine your strategies, improving ROI and maximizing engagement across various platforms."
      />
      <Components2
        Image={CadAutomation}
        Header="CAD Automation and Customization"
        Description="CAD software to streamline repetitive tasks and tailor its functionality to specific needs. Automation focuses on speeding up design processes through parametric modeling, automated drawing generation, scripting.Customization allows for the creation of custom tools, as well as integrating CAD software with other systems like PLM or ERP."
      />
      <Components1
        Image={plmsolutions}
        Header="PLM Solutions"
        Description="(PLM) Solutions are software platforms that manage the entire lifecycle of a product, from its conception and design through production, distribution, and eventual retirement. PLM solutions centralize all product-related data, facilitating collaboration across teams, ensuring compliance with industry standards, and quality management."
      />
    </>
  );
}

export default ComponentsSection;
