import React, { useState } from "react";
import "./HeroSection.css";
import "../GeneralTemplate/GeneralTemplate.css";

const SectionDescription = () => {
  const [heading, setHeading] = useState(
    "Artificial Intelligence and Machine Learning"
  );
  const [description, setDescription] = useState(
    "Artificial Intelligence and Machine Learning are transforming the landscape of modern business by enabling organizations to harness the power of data. With AI, companies can automate repetitive tasks, optimize operations, and enhance customer experiences through personalized solutions. Machine Learning algorithms analyze vast datasets to uncover patterns and insights, driving informed decision-making. Together, these technologies foster innovation, improve efficiency, and provide a competitive edge. By integrating AI and ML into their strategies, businesses can anticipate market trends, enhance product offerings, and deliver tailored services, ultimately driving growth and success in an increasingly digital world."
  );

  return (
    <div className="HeroSection-background-container">
      <section className="HeroSection_section2">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default SectionDescription;
