import React, { useState } from "react";
import "./CadAutomation.css";

const CadAutomationInfo = () => {
  const [heading, setHeading] = useState("CAD Automation and Customization");
  const [description, setDescription] = useState(
    "We provide comprehensive CAD automation and customization services designed to optimize design workflows and enhance productivity. Our expert team analyzes client needs and implements tailored solutions, including automated scripts and specialized tools, to eliminate repetitive tasks and reduce errors. This allows our clients to focus on creative design rather than manual input, ultimately leading to faster project completion times and improved overall quality. By integrating innovative CAD technologies, we empower organizations to adapt to changing demands and stay ahead of the competition. Our commitment to customization ensures that each solution is perfectly aligned with client requirements and expectations."
  );

  return (
    <div className="CadAutomation-background-container">
      <section className="CadAutomation_section2">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default CadAutomationInfo;
