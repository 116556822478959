import React, { useState, useEffect } from "react";
import "./Footer.css";
import { IconButton } from "@mui/material";
import { LinkedIn, Facebook, Phone, Email } from "@mui/icons-material";
import { LocationOn, Language } from "@mui/icons-material";

const Footer = () => {
  const [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    const fetchFooterData = async () => {
      const contactData = {
        website: "www.happytechnovation.com",
        address: "#16th main Road,\nVijayanagara\nBangalore",
        email: "info@happytechnovation.com",
        phone: "+91 6360501798",
      };
      setContactInfo(contactData);
    };

    fetchFooterData();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-container2">
          {/* Our Products Section */}
          <div className="footer-column products">
            <h3>Our Products</h3>
            <ul className="products-list">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/staffing">Staffing</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
            </ul>
          </div>

          {/* Contact Us Section */}
          <div className="footer-column contact">
            <h3>Contact Us</h3>

            {/* Social Media Icons */}
            <div className="social-icons">
              <IconButton
                aria-label="LinkedIn"
                href="https://www.linkedin.com/in/happy-technovation"
              >
                <LinkedIn className="social-icon" />
              </IconButton>
              <IconButton
                aria-label="Facebook"
                href="https://www.facebook.com/profile.php?id=100081588700211&mibextid=ZbWKwL"
              >
                <Facebook className="social-icon" />
              </IconButton>
              <IconButton
                aria-label="Gmail"
                href="mailto:info@happytechnovation.com"
              >
                <Email className="social-icon" />
              </IconButton>
            </div>

            {/* Contact Information */}
            <div className="contact-info-grid">
              <div className="contact-column">
                <ul>
                  <li className="contact-item">
                    <IconButton
                      aria-label="Email"
                      href={`mailto:${contactInfo.email}`}
                    >
                      <Email className="contact-icon" />
                    </IconButton>
                    <span className="contact-icon-text">
                      {contactInfo.email}
                    </span>
                  </li>
                  <li className="contact-item">
                    <IconButton aria-label="Address">
                      <LocationOn className="contact-icon location-icon" />
                    </IconButton>
                    <span className="contact-icon-text">
                      {contactInfo.address}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="contact-column">
                <ul className="contact-list">
                  <li className="contact-item">
                    <IconButton
                      aria-label="Phone"
                      href={`tel:${contactInfo.phone}`}
                    >
                      <Phone className="contact-icon" />
                    </IconButton>
                    <span className="contact-icon-text">
                      {contactInfo.phone}
                    </span>
                  </li>
                  <li className="contact-item">
                    <IconButton
                      aria-label="Website"
                      href={contactInfo.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Language className="contact-icon" />
                    </IconButton>
                    <span className="contact-icon-text">
                      {contactInfo.website}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <hr className="test-separator" />
        {/* Footer Bottom - Copyright Section */}
        <div className="footer-bottom">
          <p> Copyright © 2024 Happy Technovation, All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
