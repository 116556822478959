import React from "react";
import HeaderMain from "../Header/HeaderMain";
import CarouselMain from "../Carousel/CarouselMain";
import FooterMain from "../Footer/FooterMain";
import MainPartners from "../OurPartners/MainPartners";
import CadAutomation from "./LandingPageUseCase/CadAutomation";
import AutomotiveCad from "./LandingPageUseCase/Automotive";
import AIMLSection from "./LandingPageUseCase/AIMLSection";
import CVSection from "./LandingPageUseCase/CVSection";
import FullStackSection from "./LandingPageUseCase/FullStackSection";
import DigitalMarketing from "./LandingPageUseCase/DigitalMarketing";
import PLMSection from "./LandingPageUseCase/PLMSection";
import LandStaffing from "./StaffingLanding/LandStaffing";
import SuccessStories from "../SuccessStories/SuccessStory";
import ModelGrid from "../Model/ModelGrid";
import SmallSnackBar from "../SmallSnackBar/SmallSnackBar";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";

function LandingPage() {
  return (
    <div>
      <SmallSnackBar />
      <HeaderMain />
      <CarouselMain />
      <ModelGrid />
      <CadAutomation />
      <AutomotiveCad />
      <AIMLSection />
      <CVSection />
      <FullStackSection />
      <DigitalMarketing />
      <PLMSection />
      <LandStaffing />
      <MainPartners />
      <SuccessStories />
      <FooterMain />
      <ScrollToTop />
      <WhatsAppButton />
    </div>
  );
}

export default LandingPage;
