import React from "react";
import Header1 from "./../Header/Header1";
import Staffing from "./Staffing";
import FooterMain from "./../Footer/FooterMain";
import SmallSnackBar from "./../SmallSnackBar/SmallSnackBar";
import StaffingTable from "./StaffingTable";
import Testinomials from "./Testinomials";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";

function StaffingMain() {
  return (
    <div>
      <SmallSnackBar />
      <Header1 />
      <Staffing />
      <StaffingTable />
      <Testinomials />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />
    </div>
  );
}

export default StaffingMain;
