import React from "react";
import "./UseCaseTemplate.css";

function UseCaseRight({
  imageSrc,
  heading,
  problemStatement,
  solution,
  efficiency,
}) {
  return (
    <>
      <div className="usecase-right-outer-div">
        <div className="usecase-right-inner-div">
          <div className="usecase-right-content-div">
            <div className="usecase-right-heading">{heading}</div>
            <div
              className="usecase-right-problem-statement"
              dangerouslySetInnerHTML={{ __html: problemStatement }}
            ></div>
            <div
              className="usecase-right-solution"
              dangerouslySetInnerHTML={{ __html: solution }}
            ></div>
            <div
              className="usecase-right-efficiency"
              dangerouslySetInnerHTML={{ __html: efficiency }}
            ></div>
          </div>
          <div className="usecase-right-image-div">
            <img
              src={imageSrc}
              alt="Use case illustration"
              className="usecase-right-image"
            />
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
    </>
  );
}

export default UseCaseRight;
