import React from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NearMeIcon from "@mui/icons-material/NearMe";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "./Staffing.css";

const StaffingModalBox = ({
  isModalOpen,
  handleCloseModal,
  formData,
  handleChange,
  handleSubmit,
  errors = {},
  countryCode = "IN",
  phone,
  handlePhoneChange,
}) => {
  const handleFormSubmit = (event) => {
    event.preventDefault();

    const phoneNumber = parsePhoneNumberFromString(formData.Phone, countryCode);
    if (!phoneNumber) {
      alert("Please enter a valid phone number.");
      return;
    }

    const formattedPhoneNumber = `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;

    const updatedFormData = {
      ...formData,
      Phone: formattedPhoneNumber,
    };

    handleSubmit(updatedFormData);
  };

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <div className="staffing-enquiry-content">
        <IconButton
          className="staffing-enquiry-close-btn"
          onClick={handleCloseModal}
        >
          <CloseIcon className="staffing-enquiry-close-btnX" />
        </IconButton>
        <div className="staffing-enquiry-header">Join Our Team</div>
        <form className="staffing-enquiry-form" onSubmit={handleFormSubmit}>
          <TextField
            margin="dense"
            label="Name"
            name="Name"
            type="text"
            fullWidth
            required
            value={formData.Name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="Email"
            type="email"
            fullWidth
            required
            value={formData.Email}
            onChange={handleChange}
          />

          <div
            className={`phone-input-spacing ${
              errors.Phone ? "phone-input-error" : ""
            }`}
          >
            <PhoneInput
              country={countryCode.toLowerCase()}
              value={phone}
              onChange={(value, country) => handlePhoneChange(value, country)}
              inputClass="phone-input"
              buttonClass={errors.Phone ? "phone-button-error" : "phone-button"}
              dropdownClass="phone-input-dropdown"
              placeholder="Phone"
            />
            {errors.Phone && (
              <div className="helper-text-phone-container">{errors.Phone}</div>
            )}
          </div>

          <TextField
            margin="dense"
            label="Message"
            name="Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={formData.Message}
            onChange={handleChange}
          />
          <div className="staffing-enquiry-actions">
            <Button type="submit" color="success" variant="contained">
              <NearMeIcon className="staffing-buttoniconcommon" />
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default StaffingModalBox;
