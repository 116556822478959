import React, { useState } from "react";
import TitleProps from "../GeneralTemplate/TitleProps";
import CadAutomationInfo from "./CadAutomationInfo";
import ".././GeneralTemplate/GeneralTemplate.css";
import Cadautomation from "./CadAutomationImages/cad6.jpg";
import Header1 from "./../Header/Header1";
import FooterMain from "./../Footer/FooterMain";
import EnquiryModal from "../ModelBox/ModelBox";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import SmallSnackBar from "../SmallSnackBar/SmallSnackBar";
import UseCaseLeft from "../GeneralTemplate/UseCaseLeft";
import UseCaseRight from "../GeneralTemplate/UseCaseRight";
import VehicleLamp from "./CadUsecaseImages/Vehicle Lamp.png";
import HeadImpact from "./CadUsecaseImages/Head Impact.png";
import SurfaceAnalysis from "./CadUsecaseImages/Surface Analysis.png";
import ABSurface from "./CadUsecaseImages/AB Surface.png";
import SmartClean from "./CadUsecaseImages/SmartClean.png";
import BoundingBox from "./CadUsecaseImages/BoudingBox.png";
import Draft from "./CadUsecaseImages/DraftAnalysis.png";
import Draft1 from "./CadUsecaseImages/Draft1.png";
import Draft2 from "./CadUsecaseImages/Draft2.png";
import Curvature from "./CadUsecaseImages/Curvature.png";
import WeakPlastic from "./CadUsecaseImages/WeakPlastic.png";
import WallThickness from "./CadUsecaseImages/Wall thickness.png";
import SinkMark from "./CadUsecaseImages/Sink mark.png";
import B_side from "./CadUsecaseImages/B-side fixation.png";
import Locator from "./CadUsecaseImages/Locator RPS.png";
import A_side from "./CadUsecaseImages/A-side issue.png";
import CleanAnalysis from "./CadUsecaseImages/CleanAnalysis.png";
import DMU from "./CadUsecaseImages/DMU Check.png";
import Error from "./CadUsecaseImages/ErrorAnalysis.png";
import Loading from "./CadUsecaseImages/LoadingStudy.png";
import Void from "./CadUsecaseImages/VoidAnalysis.png";
import WeakSteel from "./CadUsecaseImages/Weak steel.png";
import Hemming from "./CadUsecaseImages/HemmingZone.png";
import Emboss from "./CadUsecaseImages/Emboss.png";
import Beads from "./CadUsecaseImages/Beads.png";
import U_Beads from "./CadUsecaseImages/U_Beads.png";
import V_Beads from "./CadUsecaseImages/V_Beads.png";
import MultiBeads from "./CadUsecaseImages/MultiBeads.png";
import OpenBeads from "./CadUsecaseImages/OpenBeads.png";
import BowlingRadius from "./CadUsecaseImages/BowlingRadius.png";
import HoleFeature from "./CadUsecaseImages/HoleFeature.png";
import SurfaceStamp from "./CadUsecaseImages/SurfaceStamp.png";
import CurveStamp from "./CadUsecaseImages/CurveStamp.png";
import Gussets from "./CadUsecaseImages/Gussets.png";
import FlangedHoles from "./CadUsecaseImages/FlangedHoles.png";
import CircularStamp from "./CadUsecaseImages/CircularStamp.png";
import LouverFeature from "./CadUsecaseImages/LouverFeature.png";
import UnfoldFeature from "./CadUsecaseImages/UnfoldFeature.png";
import InnerPanel from "./CadUsecaseImages/InnerPanel.png";
import InnerPanel1 from "./CadUsecaseImages/InnerPanel1.png";
import PanelFlange from "./CadUsecaseImages/PanelFlange.png";
import BSO_Glass from "./CadUsecaseImages/BSO_Glass.png";
import CadServices from "./CadServices";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import GetInTouch from "../GeneralTemplate/GetInTouch";

const Title6 = [
  {
    imageSrc: Cadautomation,
    title: "Cad Automation and Customization",
    description:
      "We offer CAD automation and customization services to streamline design processes, improve efficiency, and enhance project quality for our clients.",
    buttonLabel: "Reach Out",
  },
];

const useCaseProps1 = {
  imageSrc: VehicleLamp,
  heading: "Complete Vehicle Lamp Regulations – ECE R48  - SAE",
  problemStatement:
    "<strong>Problem Statement:</strong> Checking position, visibility, and non-visibility for all vehicle lamp variants under ECE R48 and SAE regulations is time-consuming and complex.",
  solution:
    "<strong>Solution:</strong> Automate the verification process for position, visibility, and non-visibility across all lamp variants. Ensure compliance with ECE R48 and SAE standards efficiently.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Reduces verification time by up to 80%, streamlining the process and ensuring consistent compliance across all variants.",
};

const useCaseProps2 = {
  imageSrc: HeadImpact,
  heading: "Head Impact  Analysis - ECE R21, FMVSS 201 , NCAP ",
  problemStatement:
    "<strong>Problem Statement:</strong> Head impact analysis in CAD for ECE R21, FMVSS 201, and NCAP standards is time-consuming and manually intensive, involving complex simulations and evaluations.",
  solution:
    "<strong>Solution:</strong> Implement CAD automation to streamline head impact analysis. Integrate automated simulations and evaluations to meet ECE R21, FMVSS 201, and NCAP requirements.",
  efficiency:
    "<strong>Efficiency Improvement:</strong>  Reduces analysis time by up to 70%, accelerates compliance testing, and minimizes manual effort.",
};

const useCaseProps3 = {
  imageSrc: SurfaceAnalysis,
  heading: "Styling Surface Analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Designers face challenges in verifying styling surfaces against R61 regulations, curvature requirements, and tooling feasibility during each design phase. The process is time-consuming and complex.",
  solution:
    "<strong>Solution:</strong> Automate the verification of styling surfaces for R61 compliance, curvature analysis, and tooling feasibility. Streamline checks with integrated tools.",
  efficiency:
    "<strong>Efficiency Improvement:</strong>  Reduces verification time by up to 60%, accelerates design iterations, and simplifies compliance and feasibility assessments.",
};

const useCaseProps4 = {
  imageSrc: ABSurface,
  heading: "A to B Surface Creation",
  problemStatement:
    "<strong>Problem Statement:</strong> During the design phase, creating a close body from surfaces and generating A-to-B surfaces is time-consuming and labor-intensive.",
  solution:
    "<strong>Solution:</strong> Implement automation tools to streamline the process of creating close bodies and A-to-B surfaces. Enhance efficiency with integrated CAD functionalities.",
  efficiency:
    "<strong>Efficiency Improvement:</strong>  Cuts creation time by up to 50%, allowing designers to focus on other critical tasks and speeding up the overall design process.",
};

const useCaseProps5 = {
  imageSrc: SmartClean,
  heading: "Smart Clean",
  problemStatement:
    "<strong>Problem Statement:</strong> Design release activities involve repetitive tasks such as CATDDUA, Fix All, and deleting useless elements for each individual part. These activities are time-consuming and do not add direct value to the engineering process.",
  solution:
    "<strong>Solution:</strong> Automate repetitive design release tasks, including CATDDUA, Fix All, and element cleanup, across multiple parts simultaneously.",
  efficiency:
    "<strong>Efficiency Improvement:</strong>  Reduces time spent on repetitive tasks by up to 70%, freeing designers to focus on value-added engineering activities and accelerating the design release process.",
};

const useCaseProps6 = {
  imageSrc: BoundingBox,
  heading: "Optimum Bounding Box",
  problemStatement:
    "<strong>Problem Statement:</strong> In the Concept, Design, and Tooling phases, designers spend hours creating bounding boxes to determine the optimal size of parts for securing tool blocks from suppliers.",
  solution:
    "<strong>Solution:</strong> Automate the creation of bounding boxes and the extraction of length, breadth, and width based on tool direction.",
  efficiency:
    "<strong>Efficiency Improvement:</strong>  Reduces the time required for bounding box creation by up to 80%, streamlining the process of securing tool blocks and accelerating the overall design phase.",
};

const useCaseProps7 = {
  imageSrc: Draft,
  heading: "Draft analysis for style surface",
  problemStatement:
    "<strong>Problem Statement:</strong> Performing draft analysis for surfaces manually is time-consuming and lacks clear visual indicators for results.",
  solution:
    "<strong>Solution:</strong> Automate draft analysis for surfaces with color-coded results indicating red, yellow, and green zones based on input parameters.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Reduces analysis time by up to 50%, provides clear visual feedback, and simplifies the process of identifying draft issues.",
};

const useCaseProps8 = {
  imageSrc: Draft1,
  heading: "Draft analysis for part in main tool",
  problemStatement:
    "<strong>Problem Statement:</strong> Highlighting draft angles and ensuring easy part removal from the tool is complex, and creating editable PowerPoint reports is time-consuming.",
  solution:
    "<strong>Solution:</strong> Automate draft angle highlighting in CATIA, ensure easy part removal, and generate editable PowerPoint reports.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Cuts analysis time by 60%, simplifies part removal, and speeds up report creation.",
};

const useCaseProps9 = {
  imageSrc: Draft2,
  heading: "Draft analysis in slider direction",
  problemStatement:
    "<strong>Problem Statement:</strong> Checking lifter travel and providing feedback from inputs using CATIA’s draft analysis tool is manual and lacks color-coded results.",
  solution:
    "<strong>Solution:</strong> Automate the lifter travel check and feedback process by directly integrating with CATIA's draft analysis tool, using color-coded results.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Streamlines the check process, provides clear color-coded feedback, and reduces manual effort.",
};

const useCaseProps10 = {
  imageSrc: Curvature,
  heading: "Curvature analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Highlighting curvature with the minimum baseline value for head impact zones and optimizing part geometry is manual and complex.",
  solution:
    "<strong>Solution:</strong> Automate the highlighting of curvature with the required minimum radius using CATIA's Curvature Analysis tool in the Part Design Workbench.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Simplifies curvature analysis, ensures accurate optimization, and reduces manual effort.",
};

const useCaseProps11 = {
  imageSrc: WeakPlastic,
  heading: "Weak plastic condition check",
  problemStatement:
    "<strong>Problem Statement:</strong> Identifying and highlighting areas with thickness less than the minimum input value of 0.8 mm to avoid weak plastic areas is manual and lacks clear visual indicators.",
  solution:
    "<strong>Solution:</strong> Automate the highlighting of affected areas with color-coded feedback where the thickness is below the 0.8 mm minimum input value.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Provides clear, color-coded visual feedback, ensuring areas meet minimum thickness requirements and reducing manual inspection.",
};

const useCaseProps12 = {
  imageSrc: WallThickness,
  heading: "Wall thickness analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Highlighting wall thickness variations and ensuring they meet the minimum required values is manual and lacks clear visual feedback.",
  solution:
    "<strong>Solution:</strong> Automate the highlighting of wall thickness variations using color-coded indicators if below the permitted minimum value, directly integrating with CATIA's Wall Thickness tool in the Part Design Workbench.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Provides clear, color-coded feedback on wall thickness, ensuring compliance and reducing manual effort.",
};

const useCaseProps13 = {
  imageSrc: SinkMark,
  heading: "Sink mark Analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Identifying and highlighting areas affected by insufficient values to avoid sink marks is manual and lacks visual clarity.",
  solution:
    "<strong>Solution:</strong> Automate the highlighting of affected areas with color-coded values compared to the minimum input value, if below the permitted threshold.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Provides clear, color-coded visual feedback, ensuring areas meet minimum requirements and reducing manual effort.",
};

const useCaseProps14 = {
  imageSrc: B_side,
  heading: "B-side fixation study and comparison with standard feature",
  problemStatement:
    "<strong>Problem Statement:</strong> Comparing standard features and generating reports with color-coded results is manual and time-consuming.",
  solution:
    "<strong>Solution:</strong> Automate the comparison of standard features and generate editable PowerPoint reports with color-coded results.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Speeds up comparison and report generation, ensuring accurate and easily editable documentation.",
};

const useCaseProps15 = {
  imageSrc: Locator,
  heading: "Locator RPS clearance check",
  problemStatement:
    "<strong>Problem Statement:</strong> Comparing standard features and generating reports with color-coded results is manual and time-consuming.",
  solution:
    "<strong>Solution:</strong> Automate the comparison of standard features and generate editable PowerPoint reports with color-coded results.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Speeds up comparison and report generation, ensuring accurate and easily editable documentation.",
};

const useCaseProps16 = {
  imageSrc: A_side,
  heading: "Identify the feature integration issue from A-side",
  problemStatement:
    "<strong>Problem Statement:</strong> Task: Identify protruding geometry from the A surface in the input direction.",
  solution:
    "<strong>Solution:</strong> Automate the detection of protruding geometry relative to the A surface and highlight the areas of concern.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Integrate the detection tool into the design workflow to quickly identify and address protrusions, reducing manual checks.",
};

const useCaseProps17 = {
  imageSrc: CleanAnalysis,
  heading: "Clean structure analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Task: Identify unlinked part bodies to keep the history tree clean.",
  solution:
    "<strong>Solution:</strong> Automate the detection of unlinked part bodies and provide a report to clean the history tree.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Integrate the tool into the design workflow to maintain a clean history tree with minimal manual intervention.",
};

const useCaseProps18 = {
  imageSrc: DMU,
  heading: "DMU check",
  problemStatement:
    "<strong>Problem Statement:</strong> Identify and highlight clearance and interference points from clash analysis in CATIA for a presentation.",
  solution:
    "<strong>Solution:</strong> Extract clash analysis results, capture screenshots, and annotate interference and clearance areas in a PowerPoint presentation.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Automate result extraction and screenshot capture using CATIA macros to save time and enhance accuracy.",
};

const useCaseProps19 = {
  imageSrc: Error,
  heading: "Feature Integration error analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Task: Ensure there are no gaps between B-side features and the base close volume in a plastic component. Highlight any gaps in a PowerPoint presentation, showing their locations.",
  solution:
    "<strong>Solution:</strong> Create an automated system to check for gaps between B-side features and the base volume. Highlight any gaps with color codes or shapes and include this information in a PowerPoint slide.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Integrate the gap detection and highlighting into the design workflow, automating the PowerPoint report generation to reduce manual inspection and ensure quick identification of issues.",
};

const useCaseProps20 = {
  imageSrc: Loading,
  heading: "Loading study",
  problemStatement:
    "<strong>Problem Statement:</strong> Validate and highlight the engagement of locators before clip fixation along the assembly's defined axis, ensuring compliance with the required loading direction for a plastic component.",
  solution:
    "<strong>Solution:</strong> Automate the validation process to check locator engagement prior to clip fixation, using color codes to indicate engagement status, loading clearance, locator clearance, and any clashes with the environment.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Integrate a script that automatically performs these validations in the CAD software, applying color codes and generating a report to streamline the review process and reduce human error.",
};

const useCaseProps21 = {
  imageSrc: Void,
  heading: "Void Analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Automate void space detection and highlight them in a PowerPoint presentation with color codes or curves.",
  solution:
    "<strong>Solution:</strong> Create a system to detect voids, highlight them, and generate annotated PowerPoint slides.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Integrate detection and highlighting into a streamlined workflow for faster, more accurate results.",
};

const useCaseProps22 = {
  imageSrc: WeakSteel,
  heading: "Weak steel analysis",
  problemStatement:
    "<strong>Problem Statement:</strong> Identifying and highlighting gaps less than the minimum 3 mm width to prevent catching issues is manual and lacks clear visual feedback.",
  solution:
    "<strong>Solution:</strong> Automate the highlighting of gaps less than 3 mm with color-coded output to indicate areas that need attention.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Provides clear, color-coded feedback on gap areas, reducing manual inspection and ensuring design accuracy.",
};

const useCaseProps23 = {
  imageSrc: Hemming,
  heading: "Creation of Hemming Zone",
  problemStatement:
    "<strong>Problem Statement:</strong> In the Body-in-White (BIW) assembly process, creating a precise hemming zone is critical for ensuring proper joint sealing and aesthetic finish, particularly in areas like door edges and hoods.",
  solution:
    "<strong>Solution:</strong> By implementing advanced robotic hemming commands and optimizing tool paths, the hemming process can be made more accurate and consistent.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach reduces manual rework, lowers cycle times, and improves overall production throughput by up to 20%, while simultaneously enhancing the quality of the hemming process and minimizing material waste.",
};

const useCaseProps24 = {
  imageSrc: Emboss,
  heading: "Creation Of Emboss/Diabolo",
  problemStatement:
    "<strong>Problem Statement:</strong> Inconsistent emboss or diabolo formations in BIW components lead to structural weakness, increased rework, and inefficiencies in production.",
  solution:
    "<strong>Solution:</strong>Using advanced CAD/CAM techniques, along with precise stamping and forming tools, allows for consistent creation of emboss or diabolo features with accurate depth and contour.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This automated approach improves production accuracy, reducing rework by up to 15%, and enhances the overall structural integrity of the components.",
};

const useCaseProps25 = {
  imageSrc: Beads,
  heading: "Creation Of Beads",
  problemStatement:
    "<strong>Problem Statement:</strong> Inconsistent bead formation in BIW components can lead to reduced structural rigidity, increased rework, and prolonged production times.",
  solution:
    "<strong>Solution:</strong> Automating the bead creation process with advanced forming tools ensures consistent bead depth and shape, improving structural integrity and reducing defects.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach enhances production efficiency by up to 15%, reduces material waste, and lowers rework rates, leading to faster cycle times and better component quality.",
};

const useCaseProps26 = {
  imageSrc: U_Beads,
  heading: "Creation Of U Beads",
  problemStatement:
    "<strong>Problem Statement:</strong> The creation of U beads is essential for strengthening panels and managing deformation during crashes.",
  solution:
    "<strong>Solution:</strong> By employing automated U bead forming techniques with precise control over the bead shape and depth, the process can ensure uniformity and improved strength across all parts, minimizing defects.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This automation reduces rework by 20%, enhances structural integrity, and shortens production cycle times, improving overall efficiency by approximately 15%.",
};

const useCaseProps27 = {
  imageSrc: V_Beads,
  heading: "Creation Of V Beads",
  problemStatement:
    "<strong>Problem Statement:</strong> Inconsistent V bead formation in BIW components can lead to compromised rigidity and uneven stress distribution, resulting in weaker parts and increased rework.",
  solution:
    "<strong>Solution:</strong> Automating the V bead creation process with precision forming tools ensures consistent bead shape and depth, improving part rigidity and stress distribution.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This method reduces rework by 15%, increases production speed, and enhances component durability, leading to an overall efficiency improvement of up to 12%.",
};

const useCaseProps28 = {
  imageSrc: MultiBeads,
  heading: "Creation Of Multi Beads",
  problemStatement:
    "<strong>Problem Statement:</strong> Creating multi beads in BIW components is crucial for enhancing stiffness and structural integrity. ",
  solution:
    "<strong>Solution:</strong> Using automated multi-bead forming tools with advanced control ensures uniform bead patterns and precise depths across multiple beads, enhancing component strength and reducing defects.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach boosts production efficiency by 18%, decreases rework, and shortens cycle times, while ensuring higher structural integrity and part quality.",
};

const useCaseProps29 = {
  imageSrc: OpenBeads,
  heading: "Creation Of Open Ended  Beads",
  problemStatement:
    "<strong>Problem Statement:</strong> Open-ended beads in BIW components are essential for enhancing stiffness without fully enclosed sections.",
  solution:
    "<strong>Solution:</strong> Automating the creation of open-ended beads with advanced forming tools ensures precise bead ends and uniformity, improving the component's stiffness and reducing defects.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This process enhances production efficiency by up to 15%, reduces material wastage, and lowers rework, leading to faster cycle times and better part quality.",
};

const useCaseProps30 = {
  imageSrc: BowlingRadius,
  heading: "Creation Of Bowling Radius",
  problemStatement:
    "<strong>Problem Statement:</strong> In BIW processes, creating precise bowling radii is critical for smooth surface transitions and maintaining structural integrity.",
  solution:
    "<strong>Solution:</strong> Using automated tooling with advanced control systems ensures accurate and consistent formation of bowling radii, resulting in smoother transitions and improved structural performance across parts.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This automation reduces rework by 12%, enhances surface quality, and shortens production time, leading to an overall efficiency boost of 10%.",
};

const useCaseProps31 = {
  imageSrc: HoleFeature,
  heading: "Creation Of Hole Feature",
  problemStatement:
    "<strong>Problem Statement:</strong> In BIW manufacturing, the creation of hole features is essential for assembly and weight reduction. Traditional drilling methods can result in misalignment and burr formation, leading to assembly issues and increased rework.",
  solution:
    "<strong>Solution:</strong> Implementing automated drilling and machining techniques with precise positioning and control ensures consistent hole dimensions and locations, minimizing misalignment and enhancing assembly quality.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach can reduce rework rates by 15%, improve cycle times by 20%, and enhance overall production efficiency by ensuring higher accuracy and reducing material waste.",
};

const useCaseProps32 = {
  imageSrc: SurfaceStamp,
  heading: "Creation Of Surface Stamp",
  problemStatement:
    "<strong>Problem Statement:</strong> Creating surface stamps is essential for adding features such as textures or logos. Traditional stamping methods may result in inconsistent impressions, leading to aesthetic defects and compromised part integrity.",
  solution:
    "<strong>Solution:</strong> Utilizing automated surface stamping technology with precise control over pressure and timing ensures consistent impressions, enhancing the quality and appearance of the final product.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This automation reduces production defects by up to 20%, minimizes rework, and improves cycle times by 15%, ultimately enhancing overall efficiency and product quality.",
};

const useCaseProps33 = {
  imageSrc: CurveStamp,
  heading: "Creation Of Curve Stamp",
  problemStatement:
    "<strong>Problem Statement:</strong> The creation of curve stamps is essential for achieving intricate designs and proper fitment of components. Traditional stamping methods may struggle with consistency, leading to distortion and uneven curves.",
  solution:
    "<strong>Solution:</strong> Implementing advanced curve stamping technology with precise control over curvature and pressure allows for accurate and uniform designs, improving both aesthetic and functional quality.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach can enhance production accuracy, reducing defects by 18% and shortening cycle times by 12%, resulting in improved overall efficiency and reduced rework costs.",
};

const useCaseProps34 = {
  imageSrc: Gussets,
  heading: "Creation Of Gussets",
  problemStatement:
    "<strong>Problem Statement:</strong> Gussets are vital for providing additional strength and stability to structural joints. Traditional methods of creating gussets can lead to inconsistent shapes and sizes, compromising structural integrity of rework.",
  solution:
    "<strong>Solution:</strong> Utilizing automated gusset forming processes with precise measurements and control ensures uniformity in shape and size, enhancing the strength of joints and improving overall structural performance.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This automation can reduce rework by up to 15%, improve cycle times by 10%, and enhance overall production efficiency by ensuring consistent quality and reducing material waste.",
};

const useCaseProps35 = {
  imageSrc: FlangedHoles,
  heading: "Creation Of Flanged Holes",
  problemStatement:
    "<strong>Problem Statement:</strong> The creation of flanged holes is crucial for providing secure fastening points and enhancing structural integrity. Traditional methods may result in misaligned or poorly formed flanges, leading to assembly difficulties and increased rework.",
  solution:
    "<strong>Solution:</strong> Employing automated processes for flanged hole creation ensures precise alignment and consistent flange dimensions, improving the quality and reliability of fastened joints.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This method can reduce rework rates by 15%, enhance assembly speed, and improve overall production efficiency by approximately 12%.",
};

const useCaseProps36 = {
  imageSrc: CircularStamp,
  heading: "Creation Of Circular Stamp",
  problemStatement:
    "<strong>Problem Statement:</strong> Creating circular stamps is essential for forming features like holes or decorative elements. Traditional stamping methods can lead to inconsistent dimensions and poor alignment, resulting in defects and assembly issues.",
  solution:
    "<strong>Solution:</strong> Implementing automated circular stamping techniques with precise control over force and position ensures uniformity in size and shape, improving the quality and functionality of stamped features.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach can reduce defect rates by 20%, decrease cycle times by 15%, and enhance overall production efficiency by ensuring consistent quality and minimizing rework.",
};

const useCaseProps37 = {
  imageSrc: LouverFeature,
  heading: "Creation Of Louver Feature",
  problemStatement:
    "<strong>Problem Statement:</strong> The creation of louver features is crucial for enhancing ventilation and aesthetic appeal in vehicle components. Traditional methods often result in inconsistent louver shapes and sizes, leading to airflow issues and increased rework.",
  solution:
    "<strong>Solution:</strong> Utilizing automated louver forming technology with precise control over dimensions and angles ensures uniform louver features, optimizing both functionality and appearance.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This automation can reduce rework rates by up to 18%, improve cycle times by 12%, and enhance overall production efficiency by ensuring high-quality, consistent results with minimal material waste.",
};

const useCaseProps38 = {
  imageSrc: UnfoldFeature,
  heading: "Creation Of Unfold Feature",
  problemStatement:
    "<strong>Problem Statement:</strong> In BIW manufacturing, the creation of unfold features is essential for transforming complex 3D shapes into flat patterns for efficient cutting and assembly.",
  solution:
    "<strong>Solution:</strong> Implementing advanced CAD/CAM software for the unfold feature allows for precise calculation of flat patterns, ensuring accurate dimensions and facilitating smoother transitions from 3D to 2D layouts.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach can reduce material waste by up to 20%, enhance production speed by 15%, and minimize rework, resulting in improved overall efficiency and better utilization of resources.",
};

const useCaseProps39 = {
  imageSrc: InnerPanel,
  heading:
    "Inner Panel  Flange  Construction (Inner + BSO Panel) - 10 parameter",
  problemStatement:
    "<strong>Problem Statement:</strong> Misaligned and inconsistent flanges during inner panel and BSO panel construction lead to poor joint strength and increased rework.",
  solution:
    "<strong>Solution:</strong> Automating flange construction with precise control improves alignment and consistency, enhancing joint quality and reducing defects.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This method reduces rework by 15% and improves production efficiency by 10%.",
};

const useCaseProps40 = {
  imageSrc: InnerPanel,
  heading:
    "Inner Panel  Flange  Construction (Inner + Outer+  BSO Panel) - 15 parameter",
  problemStatement:
    "<strong>Problem Statement:</strong> Inaccuracies in the flange construction of inner, outer, and BSO panels in BIW manufacturing often lead to assembly issues, improper sealing, and weakened structural integrity, causing rework and inefficiencies in the production process.",
  solution:
    "<strong>Solution:</strong> Automating the flange construction process with precise control over critical parameters ensures consistent and accurate flange formation, improving joint strength and reducing defects.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This method can reduce rework by up to 15% and improve overall production efficiency by 12%, resulting in stronger joints and better alignment of panels.",
};

const useCaseProps41 = {
  imageSrc: PanelFlange,
  heading:
    "Inner Panel  Flange  Construction (Inner + BSO Panel) - 16 parameter",
  problemStatement:
    "<strong>Problem Statement:</strong> Inconsistent flange construction between the inner panel and BSO panel can lead to poor fitment, compromised joint strength, and sealing issues, resulting in increased rework and assembly inefficiencies.",
  solution:
    "<strong>Solution:</strong> Implement automated flange forming with precise control over parameters such as flange height, angle, and width to ensure consistent, accurate flange construction for improved joint quality and sealing.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This approach reduces rework by 18%, enhances assembly efficiency, and improves sealing performance, leading to a 12% increase in overall production efficiency.",
};

const useCaseProps42 = {
  imageSrc: BSO_Glass,
  heading: "Inner Panel Frame  Construction (Inner + BSO +Glass) - 6 Parameter",
  problemStatement:
    "<strong>Problem Statement:</strong> Inaccurate inner panel frame construction, especially when integrating the BSO and glass, can lead to misalignment, poor fitment, and sealing issues, which compromise structural integrity and aesthetics.",
  solution:
    "<strong>Solution:</strong> Precise control over key parameters such as panel alignment, glass fitment, and frame strength ensures accurate construction, improving overall fitment and sealing quality.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> This process reduces alignment issues by 15%, improves assembly efficiency, and reduces production time by 10%.",
};

const Section1Data = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallSnackBar />
      <Header1 />
      <div className="TitleProps-container">
        {Title6.map((Section1) => (
          <TitleProps
            key={Section1.title}
            imageSrc={Section1.imageSrc}
            title={Section1.title}
            description={Section1.description}
            buttonLabel={Section1.buttonLabel}
            onButtonClick={handleOpenModal}
          />
        ))}

        <CadAutomationInfo />
        <CadServices />

        <h1 className="use-case-heading">USE CASES</h1>
        <div className="card-container">
          <UseCaseLeft {...useCaseProps1} />
          <UseCaseRight {...useCaseProps2} />
          <UseCaseLeft {...useCaseProps3} />
          <UseCaseRight {...useCaseProps4} />
          <UseCaseLeft {...useCaseProps5} />
          <UseCaseRight {...useCaseProps6} />
          <UseCaseLeft {...useCaseProps7} />
          <UseCaseRight {...useCaseProps8} />
          <UseCaseLeft {...useCaseProps9} />
          <UseCaseRight {...useCaseProps10} />
          <UseCaseLeft {...useCaseProps11} />
          <UseCaseRight {...useCaseProps12} />
          <UseCaseLeft {...useCaseProps13} />
          <UseCaseRight {...useCaseProps14} />
          <UseCaseLeft {...useCaseProps15} />
          <UseCaseRight {...useCaseProps16} />
          <UseCaseLeft {...useCaseProps17} />
          <UseCaseRight {...useCaseProps18} />
          <UseCaseLeft {...useCaseProps19} />
          <UseCaseRight {...useCaseProps20} />
          <UseCaseLeft {...useCaseProps21} />
          <UseCaseRight {...useCaseProps22} />
          <UseCaseLeft {...useCaseProps23} />
          <UseCaseRight {...useCaseProps24} />
          <UseCaseLeft {...useCaseProps25} />
          <UseCaseRight {...useCaseProps26} />
          <UseCaseLeft {...useCaseProps27} />
          <UseCaseRight {...useCaseProps28} />
          <UseCaseLeft {...useCaseProps29} />
          <UseCaseRight {...useCaseProps30} />
          <UseCaseLeft {...useCaseProps31} />
          <UseCaseRight {...useCaseProps32} />
          <UseCaseLeft {...useCaseProps33} />
          <UseCaseRight {...useCaseProps34} />
          <UseCaseLeft {...useCaseProps35} />
          <UseCaseRight {...useCaseProps36} />
          <UseCaseLeft {...useCaseProps37} />
          <UseCaseRight {...useCaseProps38} />
          <UseCaseLeft {...useCaseProps39} />
          <UseCaseRight {...useCaseProps40} />
          <UseCaseLeft {...useCaseProps41} />
          <UseCaseRight {...useCaseProps42} />
        </div>
      </div>
      <GetInTouch />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />

      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        errors={errors}
      />

      {loading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application" />
      )}
    </>
  );
};

export default Section1Data;
