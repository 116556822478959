import React, { useState } from "react";
import "./ComputerVision.css";

const ComputerVisionDescription = () => {
  const [heading, setHeading] = useState("Computer Vision");
  const [description, setDescription] = useState(
    "Computer Vision is a transformative technology that enables machines to interpret and understand visual information from the world around them. By leveraging advanced algorithms and deep learning techniques, it allows for the automatic extraction, analysis, and interpretation of data from images and videos. This innovation is revolutionizing various industries, including healthcare, automotive, and retail, by enabling applications such as facial recognition, object detection, and autonomous vehicles. Our commitment to integrating Computer Vision solutions enhances automation, improves decision-making, and delivers superior user experiences, positioning us at the forefront of technological advancements in a rapidly evolving digital landscape."
  );

  return (
    <div className="ComputerVision-background-container">
      <section className="ComputerVision_section2">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default ComputerVisionDescription;
