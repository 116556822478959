import React, { useEffect, useRef, useState } from "react";
import "./LandingUseCase.css";
import PLM from "../LandingPageImages/PLM.png";

function PLMSection() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry);
        if (entry.isIntersecting) {
          console.log("Element is visible!");
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="PLM-split-outer">
      <div
        ref={sectionRef}
        className={`cad-split-screen ${isVisible ? "fade-in" : ""}`}
      >
        {/* Left Side: Content */}
        <div className="cad-left">
          <div className="cad-content">
            <h1>
              Streamlining Innovation with PLM Solutions: Managing the Entire
              Product Lifecycle
            </h1>
            <p>
              Our PLM solutions provide end-to-end management for product
              development, ensuring seamless collaboration across teams and
              optimizing every stage of the product lifecycle. From concept to
              production, we enable businesses to innovate faster and more
              efficiently.
            </p>
            <ul>
              <li>Comprehensive Product Data Management</li>
              <li>Collaboration Across Design and Engineering Teams</li>
              <li>Lifecycle Workflow Automation</li>
              <li>Integration with CAD and ERP Systems</li>
            </ul>

            <a href="/plmsolution" className="cad-button">
              EXPLORE MORE
            </a>
          </div>
        </div>

        {/* Right Side: Image */}
        <div className="plm-solution">
          <img src={PLM} alt="CAD Automation" />
        </div>
      </div>
    </div>
  );
}

export default PLMSection;
