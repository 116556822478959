import React from "react";
import "./UseCaseTemplate.css";

function UseCaseLeft({
  imageSrc,
  heading,
  problemStatement,
  solution,
  efficiency,
}) {
  return (
    <>
      <div className="usecase-left-outer-div">
        <div className="usecase-left-inner-div">
          <div className="usecase-left-image-div">
            <img
              src={imageSrc}
              alt="Use case illustration"
              className="usecase-left-image"
            />
          </div>
          <div className="usecase-left-content-div">
            <div className="usecase-left-heading">{heading}</div>
            <div
              className="usecase-left-problem-statement"
              dangerouslySetInnerHTML={{ __html: problemStatement }}
            ></div>
            <div
              className="usecase-left-solution"
              dangerouslySetInnerHTML={{ __html: solution }}
            ></div>
            <div
              className="usecase-left-efficiency"
              dangerouslySetInnerHTML={{ __html: efficiency }}
            ></div>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
    </>
  );
}

export default UseCaseLeft;
