import React, { useEffect, useRef, useState } from "react";
import "./LandingUseCase.css";
import ComputerVision from "../LandingPageImages/CV.png";

function CVSection() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="CV-split-outer">
      <div
        ref={sectionRef}
        className={`automotive-cad-split-screen ${
          isVisible ? "fade-slide-in" : ""
        }`}
      >
        {/* Right Side: Image */}
        <div className="cv-right">
          <img src={ComputerVision} alt="Automotive CAD" />
        </div>
        {/* Left Side: Content */}
        <div className="automotive-cad-left">
          <div className="automotive-cad-content">
            <h1>
              Revolutionizing Computer Vision: AI-Powered Solutions for Visual
              Recognition
            </h1>
            <p>
              Our Computer Vision solutions enable businesses to harness the
              power of AI for accurate and real-time visual recognition. From
              image analysis to object detection, our tools are designed to
              enhance efficiency and precision across various industries.
            </p>
            <ul>
              <li>Automated Image Recognition</li>
              <li>Real-time Object Detection</li>
              <li>AI-based Image Processing and Enhancement</li>
              <li>Integration with Machine Learning Models</li>
            </ul>

            {/* Button for navigation */}
            <a href="/computervision" className="automotive-cad-button">
              EXPLORE MORE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CVSection;
