import React from "react";
import {
  Modal,
  TextField,
  Button,
  IconButton,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "./Careers.css";

const ApplyModal = ({
  isOpen,
  onClose,
  formData,
  handleChange,
  handleFileChange,
  handleSubmit,
  handlePhoneChange,
  errors = {},
  countryCode = "IN",
}) => {
  const handleFormSubmit = (event) => {
    event.preventDefault();

    const phoneNumber = parsePhoneNumberFromString(formData.Phone, countryCode);
    if (!phoneNumber) {
      alert("Please enter a valid phone number.");
      return;
    }

    const formattedPhoneNumber = `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;

    const updatedFormData = {
      ...formData,
      Phone: formattedPhoneNumber,
    };

    handleSubmit(updatedFormData);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="career-modal-content">
        <IconButton className="modal-close-btn" onClick={onClose}>
          <CloseIcon className="modal-close-btnX" />
        </IconButton>
        <div className="modal-header">
          <Typography className="career-header">Apply for a Job</Typography>
        </div>
        <div className="modal-body">
          <form className="apply-form" onSubmit={handleFormSubmit}>
            <TextField
              margin="dense"
              label="Name"
              name="name"
              type="text"
              fullWidth
              required
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Email"
              name="email"
              type="email"
              fullWidth
              required
              value={formData.email}
              onChange={handleChange}
            />

            <div
              className={`phone-input-spacing ${
                errors.phone ? "phone-input-error" : ""
              }`}
            >
              <PhoneInput
                country={countryCode.toLowerCase()}
                value={formData.phone}
                onChange={(value, country) => handlePhoneChange(value, country)}
                inputClass="phone-input"
                buttonClass={
                  errors.phone ? "phone-button-error" : "phone-button"
                }
                dropdownClass="phone-input-dropdown"
                placeholder="Phone"
              />
              {errors.phone && (
                <div className="helper-text-phone-container">
                  {errors.phone}
                </div>
              )}
            </div>

            <div className="resume-section">
              <div className="resume-upload">
                <Button component="label" className="upload-btn">
                  Upload Resume
                  <ArrowUpwardIcon className="upload-icon" />
                  <input type="file" hidden onChange={handleFileChange} />
                </Button>
                <Typography variant="body2" className="resume-name">
                  {formData.resumeName ? formData.resumeName : "No Resume"}
                </Typography>
              </div>
            </div>

            <FormControl fullWidth margin="dense">
              <InputLabel>Services</InputLabel>
              <Select
                name="services"
                value={formData.services}
                onChange={handleChange}
                required
                label="services"
              >
                <MenuItem value="Full Stack">Full Stack</MenuItem>
                <MenuItem value="Digital Marketing">Digital Marketing</MenuItem>
                <MenuItem value="Automotive Design">Automotive Design</MenuItem>
                <MenuItem value="AI/ML">AI/ML</MenuItem>
                <MenuItem value="CAD Automation and Customization">
                  CAD Automation and Customization
                </MenuItem>
                <MenuItem value="Computer Vision">Computer Vision</MenuItem>
                <MenuItem value="PLM Solutions">PLM Solutions</MenuItem>
              </Select>
            </FormControl>

            <TextField
              margin="dense"
              label="Message"
              name="message"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={formData.message}
              onChange={handleChange}
            />
            <div className="modal-actions">
              <Button type="submit" color="success" variant="contained">
                <NearMeIcon className="buttoniconcommon" />
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ApplyModal;
