import React from "react";
import PartnersSection from "./PartnersSection";

const MainPartners = () => {
  return (
    <div>
      <PartnersSection />
    </div>
  );
};
export default MainPartners;
