import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImageCarousel.css";

function CarouselComponent() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getCarouselData() {
    const carouselItems = [
      {
        imgSrc: "img1.jpg",
        imgAlt: "Slide 1",
        heading: "Innovating with Intelligence",
        description:
          "We leverage AI to enhance decision-making and create market-leading solutions that drive success.",
      },
      {
        imgSrc: "s3.jpg",
        imgAlt: "Slide 2",
        heading: "Empowering Teams with Expert Staffing",
        description:
          "Our staffing solutions bring top-tier professionals to your projects, ensuring excellence and innovation.",
      },
      {
        imgSrc: "fullstack3.jpg",
        imgAlt: "Slide 3",
        heading: "Innovating Across the Stack",
        description:
          "We offer a complete suite of full-stack services, combining front-end creativity with back-end strength to meet client needs.",
      },
      {
        imgSrc: "CV2.jpg",
        imgAlt: "Slide 4",
        heading: "Building a Smarter World Through Vision",
        description:
          "Our Computer Vision technologies enhance image processing, delivering high-impact solutions that drive innovation",
      },
      {
        imgSrc: "cad.jpg",
        imgAlt: "Slide 5",
        heading: "Streamlining the Future of Design",
        description:
          "With CAD automation, we optimize every step of the design process, reducing errors and accelerating project timelines.",
      },
      {
        imgSrc: "image4.jpg",
        imgAlt: "Slide 6",
        heading: "Strategic Digital Solutions for a Competitive Edge",
        description:
          "Our innovative digital marketing approaches enhance brand visibility and engagement, positioning your business ahead in the evolving digital world.",
      },
    ];

    return { carouselItems };
  }

  const { carouselItems } = getCarouselData();

  const onChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel">
      <div className="carousel_inside">
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          interval={3000}
          onChange={onChange}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="control-arrow control-prev"
              ></button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="control-arrow control-next"
              ></button>
            )
          }
        >
          {carouselItems.map((item, index) => (
            <div key={index} className="slide">
              <img
                src={item.imgSrc}
                alt={item.imgAlt}
                className="carousel-img"
              />
              <div
                className={`text-overlay ${
                  currentIndex === index ? "fade-in" : ""
                }`}
              >
                <h2>{item.heading}</h2>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default CarouselComponent;
