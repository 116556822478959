import React, { useEffect, useRef, useState } from "react";
import "./LandingUseCase.css";
import CAD from "../LandingPageImages/CAD_Automation.png";

function CadAutomation() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry);
        if (entry.isIntersecting) {
          console.log("Element is visible!");
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="cad-split-outer">
      <div
        ref={sectionRef}
        className={`cad-split-screen ${isVisible ? "fade-in" : ""}`}
      >
        {/* Left Side: Content */}
        <div className="cad-left">
          <div className="cad-content">
            <h1>
              CAD Automation and Customization: Streamline Your Design Process
            </h1>
            <p>
              Elevate Your Projects with Our CAD Automation and Customization
              Services! Tailored for the automotive, aerospace, railway, and
              energy sectors, we enhance design efficiency and precision,
              helping you deliver innovative solutions faster. Improve safety,
              modernize engineering, and optimize resources all while reducing
              costs. Let us help you transform your designs into reality contact
              us today to get started!
            </p>
            <ul>
              <li>Parametric Design Automation</li>
              <li>Customization of CAD Environment</li>
              <li>Automated Drawing Generation</li>
              <li>Data Migration</li>
              <li>Integration with PLM and ERP Systems</li>
            </ul>

            <a href="/cadautomation" className="cad-button">
              EXPLORE MORE
            </a>
          </div>
        </div>

        {/* Right Side: Image */}
        <div className="cad-right">
          <img src={CAD} alt="CAD Automation" />
        </div>
      </div>
    </div>
  );
}

export default CadAutomation;
