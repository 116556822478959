import React, { useEffect, useRef } from "react";
import { LinkedIn, Mail, Phone } from "@mui/icons-material";
import "./ContactUs.css";

const companyInfo = [
  {
    icon: <Phone />,
    label: "Phone",
    value: "+91-636-050-1798",
    href: `tel:+91-636-050-1798`,
  },
  {
    icon: <Mail />,
    label: "Email",
    value: "info@happytechnovation.com",
    href: `mailto:info@happytechnovation.com`,
  },
  {
    icon: <LinkedIn />,
    label: "LinkedIn",
    value: "HappyTechnovation",
    href: "https://www.linkedin.com/in/happy-technovation",
    target: "_blank",
  },
];

const ContactInfo = () => {
  const cardRefs = useRef([]);
  const iconRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = cardRefs.current.indexOf(entry.target);

            if (index !== -1) {
              setTimeout(() => {
                entry.target.classList.add("fade-in-card");

                setTimeout(() => {
                  if (iconRefs.current[index]) {
                    iconRefs.current[index].classList.add("fade-in-icon");
                  }
                }, 300);
              }, index * 600);
            }
          }
        });
      },
      { threshold: 0.3 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, []);

  return (
    <div className="contactInfoOuter">
      <div className="contactInfoContainer">
        {companyInfo.map((item, index) => (
          <div
            key={index}
            className="contactCard"
            ref={(el) => (cardRefs.current[index] = el)}
          >
            <div className="contactCardContent">
              <a
                className="contactIconLink"
                href={item.href}
                target={item.target || "_self"}
                rel="noopener noreferrer"
              >
                <div
                  className="contactIcon"
                  ref={(el) => (iconRefs.current[index] = el)}
                >
                  {item.icon}
                </div>
              </a>
              <div className="contactLabel">{item.label}</div>
              <a
                className="contactLink"
                href={item.href}
                target={item.target || "_self"}
                rel="noopener noreferrer"
              >
                {item.value}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactInfo;
