import React from "react";
import {
  Modal,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NearMeIcon from "@mui/icons-material/NearMe";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "./ModelBox.css";

const EnquiryModal = ({
  isModalOpen,
  handleCloseModal,
  handleChange,
  formData,
  handleSubmit,
  countryCode = "IN",
  errors = {},
}) => {
  const handlePhoneChange = (value, countryData) => {
    const phoneWithPlus = value.startsWith("+") ? value : `+${value}`;
    handleChange({ target: { name: "Phone", value: phoneWithPlus } });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const phoneNumber = parsePhoneNumberFromString(formData.Phone);
    if (!phoneNumber) {
      alert("Please enter a valid phone number.");
      return;
    }
    const formattedPhoneNumber = `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;
    const updatedFormData = { ...formData, Phone: formattedPhoneNumber };
    handleSubmit(updatedFormData);
  };

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <div className="enquiry-content">
        <IconButton className="enquiry-close-btn" onClick={handleCloseModal}>
          <CloseIcon className="enquiry-close-btnX" />
        </IconButton>
        <div className="enquiry-header">Reach Out</div>

        {/* Scrollable form content */}
        <form className="enquiry-form" onSubmit={handleFormSubmit}>
          <TextField
            margin="dense"
            label="Name"
            name="Name"
            type="text"
            fullWidth
            required
            value={formData.Name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="Email"
            type="email"
            fullWidth
            required
            value={formData.Email}
            onChange={handleChange}
          />
          <div
            className={`phone-input-spacing ${
              errors.phone ? "phone-input-error" : ""
            }`}
          >
            <PhoneInput
              country={countryCode.toLowerCase()}
              value={formData.Phone}
              onChange={handlePhoneChange}
              inputClass="phone-input"
              buttonClass={errors.phone ? "phone-button-error" : "phone-button"}
              dropdownClass="phone-input-dropdown"
              placeholder="Phone"
            />
            {errors.phone && (
              <div className="helper-text-phone-container">{errors.phone}</div>
            )}
          </div>
          <FormControl fullWidth margin="dense">
            <InputLabel>Services</InputLabel>
            <Select
              name="Services"
              value={formData.Services}
              onChange={handleChange}
              required
              label="Services"
            >
              <MenuItem value="Full Stack">Full Stack</MenuItem>
              <MenuItem value="Digital Marketing">Digital Marketing</MenuItem>
              <MenuItem value="Automotive Design">Automotive Design</MenuItem>
              <MenuItem value="AI/ML">AI/ML</MenuItem>
              <MenuItem value="CAD Automation and Customization">
                CAD Automation and Customization
              </MenuItem>
              <MenuItem value="Computer Vision">Computer Vision</MenuItem>
              <MenuItem value="PLM Solutions">PLM Solutions</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Message"
            name="Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={formData.Message}
            onChange={handleChange}
          />
        </form>

        {/* Fixed button at the bottom */}
        <div className="enquiry-actions">
          <Button type="submit" color="success" variant="contained">
            <NearMeIcon className="buttoniconcommon" />
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EnquiryModal;
