import React from "react";
import "./ModelGrid.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCog,
  faRobot,
  faBullhorn,
  faCode,
  faBrain,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons";

function ModelGrid() {
  return (
    <div className="mode-grid-outer">
      <div className="model-grid-container">
        {/* Heading */}
        <h2 className="services-heading"> OUR SERVICES </h2>
        <div className="hex-grid">
          {/* Central Hexagon */}
          <div className="hex-wrapper central-hex">
            <a href="/automotivedesign">
              <div className="hex" data-text="Automative CAD">
                <div className="hex-text">Automotive CAD</div>
                <FontAwesomeIcon icon={faCar} size="2x" />
              </div>
            </a>
          </div>

          {/* Surrounding Hexagons */}
          <div className="hex-wrapper hex-top-left">
            <a href="/cadautomation">
              <div className="hex" data-text="CAD Automation">
                <div className="hex-text">CAD Automation</div>
                <FontAwesomeIcon icon={faCog} size="2x" />
              </div>
            </a>
          </div>
          <div className="hex-wrapper hex-top">
            <a href="/computervision">
              <div className="hex" data-text="Computer Vision">
                <div className="hex-text">Computer Vision</div>
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </div>
            </a>
          </div>
          <div className="hex-wrapper hex-top-right">
            <a href="/digitalmarketing">
              <div className="hex" data-text="Digital Marketing">
                <div className="hex-text">Digital Marketing</div>
                <FontAwesomeIcon icon={faBullhorn} size="2x" />
              </div>
            </a>
          </div>
          <div className="hex-wrapper hex-bottom-left">
            <a href="/fullstack">
              <div className="hex" data-text="Full Stack">
                <div className="hex-text">Full Stack</div>
                <FontAwesomeIcon icon={faCode} size="2x" />
              </div>
            </a>
          </div>
          <div className="hex-wrapper hex-bottom-right">
            <a href="/aiml">
              <div className="hex" data-text="AI / ML">
                <div className="hex-text">AI / ML</div>
                <FontAwesomeIcon icon={faBrain} size="2x" />
              </div>
            </a>
          </div>
          <div className="hex-wrapper hex-bottom">
            <a href="/plmsolution">
              <div className="hex" data-text="PLM Solutions">
                <div className="hex-text">PLM Solutions</div>
                <FontAwesomeIcon icon={faProjectDiagram} size="2x" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelGrid;
