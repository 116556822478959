import Swal from "sweetalert2";
import "./custom.css";

export function customAlert(title, text, icon) {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: "OK",
    confirmButtonColor: "#2e7d32",
    background: "#f3f3f3",
    customClass: {
      container: "my-custom-container-class",
      title: "my-custom-title-class",
      content: "my-custom-content-class",
      confirmButton: "my-custom-button-class",
    },
  });
}
