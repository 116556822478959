import React from "react";
import "./Staffing.css";

function StaffingTable() {
  const tableData = [
    {
      id: 1,
      role: "Associate Design Engineers",
      domain:
        "Automotive Interior/Exterior Plastic Trims Design/BIW (CATIA V5/UG)",
    },
    {
      id: 2,
      role: "Associate Design Engineers",
      domain: "Lighting Interior/Exterior / Wiring Harness (CATIA V5/UG)",
    },
    {
      id: 3,
      role: "Associate Design Engineers",
      domain: "Seating Design ( Structures/Mechanism/Foam) (CATIA V5/UG)",
    },
    { id: 4, role: "CAE Engineers", domain: "Meshing ( Hypermesh /ANSA)" },
    {
      id: 5,
      role: "CAE -Stress Engineers",
      domain: "Analysis (Nastran, Abaqus, LsDyna)",
    },
    {
      id: 6,
      role: "Associate Solution Developer",
      domain: "Cad Automation (Creo,Catia V5 ,UGNX)",
    },
    { id: 7, role: "Solution Developer", domain: "Catia CAA Customization" },
    { id: 8, role: "Full Stack", domain: "Web development" },
  ];

  return (
    <div className="staffing_table_outerContainer">
      <div className="staffing_table_innerContainer">
        <h3>Readily Deployable Talent Pool</h3>
        <table className="staffing_table_table">
          <thead>
            <tr>
              <th>Sl.no</th>
              <th>Role/Position</th>
              <th>Domain</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.role}</td>
                <td>{item.domain}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StaffingTable;
