import React from "react";
import "./SuccessStory.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SuccessStory = () => {
  const stories = [
    {
      title: "Staffing Success",
      description:
        "We provided specialized resources to a tech client, helping them fill crucial roles and achieve project success with a 100% satisfaction rate.",
    },

    {
      title: "CAD Automation",
      description:
        "By automating CAD processes for a major engineering firm, we reduced design time by 40% and enhanced overall project efficiency.",
    },
    {
      title: "Problem Solving",
      description:
        "We solved critical operational challenges for a manufacturing client, improving process flow and boosting production capacity by 30%.",
    },
    {
      title: "AI in CAD",
      description:
        "Our AI integration for a leading construction firm improved CAD design precision, cutting design errors by 50% and speeding up approvals.",
    },
    {
      title: "Niche Resources",
      description:
        "By providing hard-to-find experts, we enabled a global client to execute a complex project ahead of schedule, ensuring high-quality deliverables.",
    },
    {
      title: "Vision for Quality",
      description:
        "Implemented advanced computer vision systems for a client’s production line, reducing defect rates by 20% and ensuring consistent quality.",
    },
    {
      title: "Private AI",
      description:
        "Developed a custom AI model for a healthcare provider, improving diagnostics accuracy and reducing processing time by 35%.",
    },
    {
      title: "3D Search",
      description:
        "We enabled a design team to compare complex 3D models efficiently, reducing development cycles by 25% with our part similarity search tool.",
    },
    {
      title: "Object Detection",
      description:
        "Implemented object detection technology for an automotive client, streamlining their production process and reducing manual checks by 60%.",
    },
    {
      title: "ISO Certified",
      description:
        "Helped a manufacturing client obtain ISO certification, boosting their market credibility and aligning with global standards.",
    },
    {
      title: "Team Growth",
      description:
        "Grew our team to over 20 in-house experts, ready to tackle any technical challenge with unmatched proficiency and experience.",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 766 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 766, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button className="custom-arrow left-arrow" onClick={onClick}>
        <ArrowBackIosIcon />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button className="custom-arrow right-arrow" onClick={onClick}>
        <ArrowForwardIosIcon />
      </button>
    );
  };

  return (
    <div className="success-stories-container">
      <div className="success-story-container">
        <h2 className="success-story-heading">SUCCESS STORIES</h2>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          customTransition="all 0.5s"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {stories.map((story, index) => (
            <div className="success-story" key={index}>
              <div className="success-story-content">
                <h3 className="success-story-title">{story.title}</h3>
                <p className="success-story-description">{story.description}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default SuccessStory;
