import React, { useEffect, useRef, useState } from "react";
import "./LandingUseCase.css";
import DigitalMarket from "../LandingPageImages/digital_marketing.png";

function DigitalMarketing() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="Digital-split-outer">
      <div
        ref={sectionRef}
        className={`automotive-cad-split-screen ${
          isVisible ? "fade-slide-in" : ""
        }`}
      >
        {/* Right Side: Image */}
        <div className="automotive-cad-right">
          <img src={DigitalMarket} alt="Automotive CAD" />
        </div>
        {/* Left Side: Content */}
        <div className="automotive-cad-left">
          <div className="automotive-cad-content">
            <h1>
              Driving Success in Digital Marketing: Data-Driven Campaigns for
              Maximum Reach
            </h1>
            <p>
              Our Digital Marketing solutions leverage data and analytics to
              create targeted campaigns that engage your audience and drive
              results. From SEO to social media management, we offer
              comprehensive strategies to enhance your brand's online presence.
            </p>
            <ul>
              <li>Search Engine Optimization (SEO)</li>
              <li>Social Media Marketing</li>
              <li>Pay-Per-Click (PPC) Campaigns</li>
              <li>Content Creation and Strategy</li>
            </ul>
            <a href="/digitalmarketing" className="automotive-cad-button">
              EXPLORE MORE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalMarketing;
