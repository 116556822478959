import React, { useState } from "react";
import "./FullStack.css";

const FullStackInfo = () => {
  const [heading, setHeading] = useState("Full Stack");
  const [description, setDescription] = useState(
    "Full Stack Developers play a crucial role in creating dynamic web applications by seamlessly integrating both front-end and back-end technologies. They possess a comprehensive understanding of various programming languages and frameworks, enabling them to design user-friendly interfaces while also managing server-side logic and database interactions. Their versatility allows them to handle multiple aspects of a project, from initial design to deployment and maintenance. By collaborating with cross-functional teams, Full Stack Developers ensure that applications meet both technical specifications and user needs, ultimately delivering high-quality, efficient solutions that drive business success and enhance user experiences. Their expertise is invaluable in today’s tech-driven landscape."
  );

  return (
    <div className="FullStack-background-container">
      <section className="Fullstack_section2">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default FullStackInfo;
