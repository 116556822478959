import React from "react";
import "./plmSolutions.css";
import logo1 from "./logo11.png";
import logo2 from "./logo2.png";
import logo3 from "./logo333.png";
import logo4 from "./logo4.jpg";
import plm from "./png0.png";

const PLMLayout = () => {
  return (
    <div className="plm-layout">
      {/* Logos section */}
      <div className="plm-logos">
        <img src={logo1} alt="Logo 1" className="plm-logo" />
        <img src={logo2} alt="Logo 2" className="plm-logo" />
        <img src={logo3} alt="Logo 3" className="plm-logo" />
        <img src={logo4} alt="Logo 4" className="plm-logo" />
      </div>

      {/* Image below logos */}
      <div className="plm-image-container">
        <img
          src={plm}
          alt="Product Lifecycle Data"
          className="plm-main-image"
        />
      </div>
    </div>
  );
};

export default PLMLayout;
