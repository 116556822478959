import React from "react";
import "./SmallSnackBar.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";

function SmallSnackBar() {
  return (
    <div className="header-snack-top-bar-background-color">
      {/* _________________linked in section__________ */}
      <div className="header-snack-top-bar-width-conatiner">
        <a href="https://www.linkedin.com/in/happy-technovation">
          <LinkedInIcon className="social-media-icons-top-snack-bar" />
        </a>

        {/* ________________________Facebook section__________________ */}
        <a href="https://www.facebook.com/profile.php?id=100081588700211&mibextid=ZbWKwL">
          <FacebookIcon className="social-media-icons-top-snack-bar" />
        </a>

        {/* _____________________mailinfo section______________________ */}
        <div className="mail-info-container">
          <MailIcon className="social-media-icons-top-snack-bar-mail" />
          <span className="mail-info-text">info@happytechnovation.com</span>
        </div>
      </div>
    </div>
  );
}

export default SmallSnackBar;
