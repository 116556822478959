import React, { useState } from "react";
import "../GeneralTemplate/GeneralTemplate.css";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import fullstack from "../GeneralTemplate/c1.png";
import EnquiryModal from "../ModelBox/ModelBox";

const GetInTouch = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);

    try {
      console.log("Submitting data:", formData);
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="getintouch-container">
        <div className="getintouch-image">
          <img src={fullstack} alt="Description" className="responsive-image" />
        </div>
        <div className="getintouch-content">
          <h1 className="getintouch-heading">
            {" "}
            Connect with our Sales Experts
          </h1>
          <p className="getintouch-description">
            Our dedicated sales team is ready to assist you in finding the
            perfect solutions tailored to your business needs. Whether you're
            looking for insights on our products or services, our experts
            provide personalized guidance to ensure a smooth, informed
            decision-making process. Collaborate with us to explore how we can
            drive growth, efficiency, and innovation together.
          </p>
          <button className="getintouch-button" onClick={handleOpenModal}>
            Reach Out
          </button>
        </div>
      </div>

      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleChange={handleChange}
        formData={formData}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        errors={errors}
      />

      {isLoading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application..." />
      )}
    </>
  );
};

export default GetInTouch;
