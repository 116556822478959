import React, { useState } from "react";
import "./Staffing.css";
import backgroundImage from "./StaffingImages/staffing.jpg";
import descriptionImage from "./StaffingImages/description.png";
import StaffingModalBox from "./StaffingModalBox";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import ProcessProgressCircle from "./../ProcessProgressCircle/ProcessProgressCircle";

function Staffing() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value, country) => {
    let updatedPhoneNumber = value;

    if (country.dialCode !== "91") {
      updatedPhoneNumber = `+${country.dialCode} ${value.slice(
        country.dialCode.length
      )}`;
    }

    handleChange({
      target: {
        name: "Phone",
        value: updatedPhoneNumber,
      },
    });
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(baseURL + "/contact-us", formData);
      console.log("Response from backend:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Message: "",
      });
      setPhone("");

      handleCloseModal();
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="staffing-container">
      {/* Hero Section */}
      <section className="staffing-hero">
        <img
          src={backgroundImage}
          alt="Staffing Hero"
          className="staffing-hero-image"
        />
        <div className="staffing-hero-content">
          <h1 className="staffing-hero-title">Our Staffing Solutions</h1>
          <p className="staffing-hero-description">
            Our contract-to-hire model lets you assess a candidate's performance
            and fit before committing, offering more hiring flexibility.
          </p>
          <button className="staffing-cta-button" onClick={handleOpenModal}>
            Join Our Team
          </button>
        </div>
      </section>

      {/* Description Section */}
      <section className="staffing-description">
        <div className="staffing-image-container">
          <img src={descriptionImage} alt="Staffing Solutions" />
        </div>
        <div className="staffing-description-text">
          <p>
            We provide top-tier resources through our comprehensive Contract
            Outsourcing services, tailored to meet your organization's
            mission-critical needs. Our flexible approach allows you to
            efficiently manage your budget while addressing any project-specific
            time constraints. The professional service charges are customized
            based on the experience and expertise of each candidate, ensuring
            you receive the most suitable talent.
          </p>
          <p>
            In addition, our Contract-to-Hire model offers a seamless transition
            from contract to permanent employment. After an initial contract
            period, you have the opportunity to assess performance and cultural
            fit before absorbing candidates as full-time employees, ensuring
            long-term success and satisfaction for your organization.
          </p>
        </div>
      </section>

      {loading && <ProcessProgressCircle RequiredProgressName="Submitting" />}

      <StaffingModalBox
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        phone={phone}
      />
    </div>
  );
}

export default Staffing;
