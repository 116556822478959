import React, { useState } from "react";
import "./HeroSection.css";
import TitleProps from "../GeneralTemplate/TitleProps";
import aiml from "./AimlImages/ai_image.jpg";
import SectionDescription from "./SectionDescription";
import ".././GeneralTemplate/GeneralTemplate.css";
import EnquiryModal from "../ModelBox/ModelBox";
import Header1 from "./../Header/Header1";
import FooterMain from "./../Footer/FooterMain";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../http";
import SmallSnackBar from "../SmallSnackBar/SmallSnackBar";
import UseCaseLeft from "../GeneralTemplate/UseCaseLeft";
import UseCaseRight from "../GeneralTemplate/UseCaseRight";
import CAD from "./AImlUsecaseImages/3D CAD.png";
import GenAi from "./AImlUsecaseImages/GEN AI.png";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import GetInTouch from "../GeneralTemplate/GetInTouch";

const Title3 = [
  {
    imageSrc: aiml,
    title: "Artificial Intelligence and Machine Learning",
    description:
      "Artificial Intelligence and Machine Learning empower businesses to leverage data insights, automate processes, and enhance decision-making for innovative solutions.",
    buttonLabel: "Reach Out",
  },
];

const useCaseProps1 = {
  imageSrc: GenAi,
  heading: "Chat With your Own Document ",
  problemStatement:
    "<strong>Problem Statement:</strong> Finding information in large documents is slow and frustrating.",
  solution:
    "<strong>Solution:</strong> Use Generative AI to chat with documents. Ask questions and get instant answers from the content.",
  efficiency:
    "<strong>Efficiency Improvement:</strong> Saves up to 70% of time spent searching for information.",
};

const useCaseProps2 = {
  imageSrc: CAD,
  heading: "3D CAD Similarity Search",
  problemStatement:
    "<strong>Problem Statement:</strong> Identifying similar 3D CAD models is time-consuming and often inaccurate.",
  solution:
    "<strong>Solution:</strong> Use Generative AI to perform similarity searches on 3D CAD models. Quickly find and compare similar designs.",
  efficiency:
    "<strong>Efficiency Improvement:</strong>  Increases accuracy and reduces search time by up to 60%.",
};

const Section1Data = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallSnackBar />
      <Header1 />
      <div className="TitleProps-container">
        {Title3.map((Section1) => (
          <TitleProps
            key={Section1.title}
            imageSrc={Section1.imageSrc}
            title={Section1.title}
            description={Section1.description}
            buttonLabel={Section1.buttonLabel}
            onButtonClick={handleOpenModal}
          />
        ))}
        <SectionDescription />
        <h1 className="use-case-heading">USE CASES</h1>
        <div className="card-container">
          <UseCaseLeft {...useCaseProps1} />
          <UseCaseRight {...useCaseProps2} />
        </div>
      </div>
      <GetInTouch />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />

      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
      />

      {loading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application..." />
      )}
    </>
  );
};

export default Section1Data;
