import React from "react";
import Header1 from "../Header/Header1";
import FooterMain from "../Footer/FooterMain";
import ContactUs from "./ContactUs";
import ContactInfo from "./ContactInfo";
import SmallSnackBar from "./../SmallSnackBar/SmallSnackBar";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";

function ContactUsMain() {
  return (
    <div>
      <SmallSnackBar />
      <Header1 />
      <ContactUs />
      <ContactInfo />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />
    </div>
  );
}

export default ContactUsMain;
