import React from "react";
import ImageCarousel from "./ImageCarousel";

const CarouselMain = () => {
  return (
    <div>
      <ImageCarousel />
    </div>
  );
};
export default CarouselMain;
