import React, { useState } from "react";
import TitleProps from "../GeneralTemplate/TitleProps";
import AutomativeCADDescription from "./AutomativeCADinfo";
import automativecad from "./AutomativeCADImages/ca4.jpg";
import ".././GeneralTemplate/GeneralTemplate.css";
import Header1 from "./../Header/Header1";
import FooterMain from "./../Footer/FooterMain";
import EnquiryModal from "../ModelBox/ModelBox";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import SmallSnackBar from "./../SmallSnackBar/SmallSnackBar";
import NormalUseCaseLeft from "../GeneralTemplate/NormalUseCaseLeft";
import NormalUsecaseRight from "../GeneralTemplate/NormalUsecaseRight";
import Assembly from "./AutomativeUsecaseImages/Assembly.png";
import Features from "./AutomativeUsecaseImages/Features.png";
import FrontDoor from "./AutomativeUsecaseImages/FrontDoor.png";
import DoorModule from "./AutomativeUsecaseImages/DoorModule.png";
import DoorModule1 from "./AutomativeUsecaseImages/Door Module1.png";
import Assembly1 from "./AutomativeUsecaseImages/Assembly1.png";
import DoorTrim from "./AutomativeUsecaseImages/DoorTrim.png";
import LowerSubstrate from "./AutomativeUsecaseImages/LowerSubstrate.png";
import WindowRegulator from "./AutomativeUsecaseImages/WindowRegulator.png";
import Sealing from "./AutomativeUsecaseImages/SealingSystem.png";
import DoorTrim1 from "./AutomativeUsecaseImages/DoorTrim1.png";
import ArmRest from "./AutomativeUsecaseImages/ArmRest.png";
import Lifter from "./AutomativeUsecaseImages/Window Lifter.png";
import Lifter1 from "./AutomativeUsecaseImages/Window Lifter1.png";
import Panel from "./AutomativeUsecaseImages/InstrumentPanel.png";
import Asm from "./AutomativeUsecaseImages/Panel_ASM.png";
import GloveBox from "./AutomativeUsecaseImages/GloveBox.png";
import Steering from "./AutomativeUsecaseImages/Covering Steering.png";
import LHD from "./AutomativeUsecaseImages/Steering LHD.png";
import Panel1 from "./AutomativeUsecaseImages/PanelASM.png";
import Env from "./AutomativeUsecaseImages/Env components.png";
import Molding from "./AutomativeUsecaseImages/Molding.png";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import GetInTouch from "../GeneralTemplate/GetInTouch";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";

const Title4 = [
  {
    imageSrc: automativecad,
    title: "Automotive Design",
    description:
      "Our automotive design integrates innovative engineering and sophisticated aesthetics to deliver high-performance vehicles that prioritize user experience and sustainability.",
    buttonLabel: "Reach Out",
  },
];

const useCase1 = {
  imageSrc: Assembly,
  header: "ASSEMBLY / ATTACHMENTS",
  description:
    "Providing advanced solutions for B-pillar lower ASSEMBLY/ATTACHMENTS, ensuring perfect alignment of door and pillar trims with BIW for superior vehicle performance and durability.",
};

const useCase2 = {
  imageSrc: Features,
  header: "ASSEMBLY / ATTACHMENTS",
  description:
    "Expertise in seat belt assembly, focusing on precise integration of the D-ring into the vehicle structure, ensuring secure attachment and seamless alignment with the seat belt system.",
};

const useCase3 = {
  imageSrc: FrontDoor,
  header: "FRONT DOOR TRIM ASSY",
  description:
    "Expert in front door trim assembly, ensuring precise fitment and alignment with door panels for enhanced vehicle aesthetics, functionality, and noise reduction.Enhanced vehicle performance and safety.Reduced road noise and vibrations, leading to a more comfortable driving experience.Streamlined assembly processes through collaboration with design and engineering teams.",
};

const useCase4 = {
  imageSrc: DoorModule,
  header: "DOOR MODULE EXPERIENCE",
  description:
    "Delivering comprehensive door module solutions, integrating sealing systems, window regulators, latches, exterior handles, and side impact bolsters for superior vehicle performance and occupant safety.",
};

const useCase5 = {
  imageSrc: DoorModule1,
  header: "DOOR MODULE",
  description:
    "Designed and assembled door modules with double-arm window lifters, ensuring efficient load distribution and reliable window performance, while improving vehicle comfort and functionality.",
};

const useCase6 = {
  imageSrc: Assembly1,
  header: "ASSEMBLY1",
  description:
    "Optimized A-pillar and instrument panel integration ensures seamless alignment with airbags and other safety systems, enhancing overall vehicle safety and compliance with regulations.",
};

const useCase7 = {
  imageSrc: DoorTrim,
  header: "DOOR TRIM",
  description:
    "Door trim support enhances vehicle interior quality by providing structural reinforcement, ensuring durability and a secure fit, resulting in a refined and comfortable cabin experience for customers.",
};

const useCase8 = {
  imageSrc: DoorTrim1,
  header: "DOOR TRIM",
  description:
    "Combining door trim support with latch support provides precise alignment and secure attachment, ensuring smooth latch engagement and a reliable closure system for optimal user experience.",
};

const useCase9 = {
  imageSrc: LowerSubstrate,
  header: "LOWER SUBSTRATE",
  description:
    "Utilizing ABS (Acrylonitrile Butadiene Styrene) for lower substrate material provides exceptional strength, durability, and impact resistance, ensuring long-lasting performance and structural integrity for vehicle components.",
};

const useCase10 = {
  imageSrc: WindowRegulator,
  header: "WINDOW REGULATOR",
  description:
    "Designed for improved load distribution, double rail window regulators offer increased durability and reliability, ensuring windows operate seamlessly even under frequent use, double rail window regulators enhance safety by preventing misalignment or jamming, providing a more secure and reliable vehicle experience",
};

const useCase11 = {
  imageSrc: Sealing,
  header: "SEALING SYSTEM",
  description:
    "Sealing systems provide superior protection against water and dust ingress, improving the vehicle’s durability and ensuring long-lasting protection for interior components.",
};

const useCase12 = {
  imageSrc: ArmRest,
  header: "ARMREST ATTACHMENT",
  description:
    "Robust armrest attachment points ensure structural integrity, allowing for ergonomic adjustments and customization options that cater to diverse customer needs and preferences.",
};

const useCase13 = {
  imageSrc: Lifter,
  header: "SINGLE RAIL WINDOW LIFTER",
  description:
    "Cost-effective and lightweight, single rail window lifters improve fuel efficiency without compromising on durability or window stability, making them an attractive option for vehicle manufacturers.",
};

const useCase14 = {
  imageSrc: Lifter1,
  header: "SINGLE RAIL WINDOW LIFTER",
  description:
    "The simplified mechanism of single rail window lifters ensures ease of assembly and maintenance, reducing production complexity while delivering consistent functionality over time.",
};

const useCase15 = {
  imageSrc: Panel,
  header: "INSTRUMENT PANEL ASSY",
  description:
    "The Instrument Panel Assembly (INSTRUMENT PANEL ASSY) is a critical component in the automotive industry, responsible for housing key controls, gauges, and displays for the vehicle's operation. It ensures driver interaction with essential systems like speed, fuel levels, engine status, and navigation. Designed for safety, functionality, and ergonomics, the assembly integrates advanced features like infotainment systems, climate control, and electronic interfaces.",
};

const useCase16 = {
  imageSrc: Asm,
  header: "PANEL ASM-INST LWR",
  description:
    "The Lower Instrument Panel Assembly (PANEL ASM-INST LWR) is a structural and functional part of a vehicle's dashboard, located beneath the main instrument cluster. It provides support for various components such as storage compartments, lower controls, and airbag systems.",
};

const useCase17 = {
  imageSrc: GloveBox,
  header: "GLOVEBOX ASM",
  description:
    "The Glovebox Assembly (GLOVEBOX ASM) is a key interior component of a vehicle, typically located in the front passenger side of the dashboard. It serves as a secure storage compartment for essential documents, tools, and personal items. Modern glovebox assemblies may include additional features such as locks, cooling systems, lighting, and airbag integration.",
};

const useCase18 = {
  imageSrc: Steering,
  header: "COVER STEERING COLUMN",
  description:
    "The Steering Column Cover is a protective casing that encloses the steering column, safeguarding the internal components such as wiring, sensors, and switches. It helps maintain the aesthetic appeal of the vehicle's interior while protecting critical elements like the ignition system and steering wheel controls from dust, debris, and accidental damage.",
};

const useCase19 = {
  imageSrc: LHD,
  header: "INST. COV. STEERING LHD",
  description:
    "The Instrument Cover Steering LHD (Left-Hand Drive) is a protective panel located around the steering wheel in left-hand drive vehicles. It serves to shield the internal mechanisms of the steering system, such as wiring, sensors, and controls, while maintaining a clean and streamlined look. This cover is typically positioned on the dashboard, surrounding the steering column.",
};

const useCase20 = {
  imageSrc: Panel1,
  header: "PANEL ASM-I/P TR",
  description:
    "The Instrument Panel Assembly - Trim (PANEL ASM-I/P TR) is a decorative and functional component of the vehicle’s dashboard. It serves to cover and finish the surface of the instrument panel, providing an aesthetically pleasing look while also protecting underlying components such as wiring, controls, and air vents.",
};

const useCase21 = {
  imageSrc: Molding,
  header: "MOLDING ASM-I/P CTR",
  description:
    "The Instrument Panel Center Molding Assembly (MOLDING ASM-I/P CTR) is a central trim component located on the dashboard of a vehicle. It provides both structural and aesthetic functions by covering gaps, seams, and joints in the center area of the instrument panel.",
};

const useCase22 = {
  imageSrc: Env,
  header: "Environment Components",
  description:
    "The HVAC system regulates cabin temperature and air quality, ensuring passenger comfort in various climates. The Cross Car Beam provides structural support to the dashboard, improving safety and reducing vibration while securing essential components like the steering column. The Navigation Display, integrated into the infotainment system, offers real-time GPS navigation, route guidance, and traffic updates, contributing to a seamless and convenient driving experience.",
};

const Section1Data = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallSnackBar />
      <Header1 />
      <div className="TitleProps-container">
        {Title4.map((Section1) => (
          <TitleProps
            imageSrc={Section1.imageSrc}
            title={Section1.title}
            description={Section1.description}
            buttonLabel={Section1.buttonLabel}
            onButtonClick={handleOpenModal}
          />
        ))}
        <AutomativeCADDescription />

        <h1 className="use-case-heading">USE CASES</h1>
        <NormalUseCaseLeft {...useCase1} />
        <NormalUsecaseRight {...useCase2} />
        <NormalUseCaseLeft {...useCase3} />
        <NormalUsecaseRight {...useCase4} />
        <NormalUseCaseLeft {...useCase5} />
        <NormalUsecaseRight {...useCase6} />
        <NormalUseCaseLeft {...useCase7} />
        <NormalUsecaseRight {...useCase8} />
        <NormalUseCaseLeft {...useCase9} />
        <NormalUsecaseRight {...useCase10} />
        <NormalUseCaseLeft {...useCase11} />
        <NormalUsecaseRight {...useCase12} />
        <NormalUseCaseLeft {...useCase13} />
        <NormalUsecaseRight {...useCase14} />
        <NormalUseCaseLeft {...useCase15} />
        <NormalUsecaseRight {...useCase16} />
        <NormalUseCaseLeft {...useCase17} />
        <NormalUsecaseRight {...useCase18} />
        <NormalUseCaseLeft {...useCase19} />
        <NormalUsecaseRight {...useCase20} />
        <NormalUseCaseLeft {...useCase21} />
        <NormalUsecaseRight {...useCase22} />
      </div>
      <GetInTouch />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />
      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        errors={errors}
      />

      {loading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application" />
      )}
    </>
  );
};

export default Section1Data;
