import React, { useState } from "react";
import "./plmSolutions.css";

const PlmSolutionInfo = () => {
  const [heading, setHeading] = useState("PLM Solutions");
  const [description, setDescription] = useState(
    "Our PLM Solutions empower businesses to manage the entire product lifecycle efficiently, from initial concept through design, manufacturing, and service. By integrating data, processes, and people, we facilitate seamless collaboration across teams, reducing time-to-market and enhancing product quality. Our user-friendly interface and customizable features enable organizations to adapt to changing market demands and regulatory requirements. With robust analytics and reporting capabilities, our PLM Solutions provide valuable insights that drive informed decision-making and foster innovation. Partner with us to transform your product development processes, optimize resources, and achieve sustainable growth in today's competitive landscape. Unlock your potential with our cutting-edge solutions."
  );

  return (
    <div className="PlmSolution-background-container">
      <section className="PlmSolution_section2">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
    </div>
  );
};

export default PlmSolutionInfo;
