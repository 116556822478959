import React from "react";
import "./AboutUs.css";
import About from "./AboutImages/about.jpg";
import Mission from "./AboutImages/About_Mission.jpg";

const AboutUs = () => {
  return (
    <div className="about-us">
      {/* Full-width Image Section with Text */}
      <div className="about-us-image">
        <img src={About} alt="About Us Banner" />
        <div className="about-us-text-wrapper">
          <div className="about-us-text">
            <h2>Our mission is to design a more enlightened way of working.</h2>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="about-us-content">
        <h2>About Us</h2>
        <p>
          Founded in 2019, by a team of professional, Happy Technovation is
          Young and Technology enabler for different industry verticals. Our
          team dedicated to cater global market like engineering, staffing and
          training services for our client’s success and efficiency.
        </p>
        <p>
          We highly believe in client satisfaction and to ensure that we strive
          our clients. To be responsive, creative team players with integrity
          and zeal to meet the commitments, made to external and internal
          Customers We deliver clear leadership, characterized by effective
          design, innovation, consultation, communication and collaboration.
        </p>
      </div>

      <div className="about-us-next-section">
        <div className="about-us-next-content">
          <div className="text-section">
            <h2>Our Mission</h2>
            <p>
              At Happy Technovation, our core values are built around
              innovation, customer satisfaction, and commitment to excellence.
              We believe in building long-term partnerships with our clients and
              fostering an environment that encourages creativity.
            </p>
            <p>
              Our team continually strives to provide top-notch services that
              empower businesses to achieve their goals and stay ahead in the
              ever-changing technological landscape.
            </p>
          </div>
          <div className="image-section">
            <img src={Mission} alt="Our Values" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
