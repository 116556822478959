import React, { useState } from "react";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "./ContactUs.css";
import contact from "./ContactImage/contact.png";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";

function ContactUs() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value, countryData) => {
    const phoneWithPlus = value.startsWith("+") ? value : `+${value}`;

    setFormData({
      ...formData,
      phone: phoneWithPlus,
    });
  };

  const validatePhoneNumber = (phone, countryCode) => {
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode);
    if (phoneNumber && phoneNumber.isValid()) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const countryCode = formData.phone.split(" ")[0] || "IN";

    if (!validatePhoneNumber(formData.phone, countryCode)) {
      setErrors({ phone: "Please enter a valid phone number." });
      return;
    }

    setLoading(true);

    const phoneNumber = parsePhoneNumberFromString(formData.phone, countryCode);
    const formattedPhone = phoneNumber
      ? `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`
      : formData.phone;

    const data = {
      Name: formData.fullName,
      Email: formData.email,
      Phone: formattedPhone,
      Message: formData.message,
    };

    try {
      const response = await fetch(`${baseURL}/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        customAlert("", "Form submitted successfully", "success");
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          message: "",
        });
        setErrors({});
      } else {
        const result = await response.json();
        console.error(
          result.message || "Form submission failed. Please try again."
        );
        customAlert("", "Failed to submit the form", "error");
      }
    } catch (error) {
      console.error("Form submission failed. Please try again.");
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-outer-container">
      <Grid container spacing={2} className="contact-container">
        <Grid item xs={12} md={6}>
          <Box className="contact-form-box">
            <Typography variant="h5" gutterBottom className="contact-bold-text">
              Join the Conversation
            </Typography>

            {loading ? (
              <ProcessProgressCircle RequiredProgressName="Submitting" />
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="What's your full name?"
                  required
                />

                <TextField
                  label="Email address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  type="email"
                  placeholder="you@example.com"
                  required
                />

                <div
                  className={`phone-input-spacing ${
                    errors.phone ? "phone-input-error" : ""
                  }`}
                >
                  <PhoneInput
                    country="in"
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    inputClass="phone-input"
                    buttonClass={
                      errors.phone ? "phone-button-error" : "phone-button"
                    }
                    dropdownClass="phone-input-dropdown"
                    placeholder="Phone"
                  />
                  {errors.phone && (
                    <div className="helper-text-phone-container">
                      {errors.phone}
                    </div>
                  )}
                </div>

                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  placeholder="Write your message for the team here"
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-button"
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6} className="contact-image-container">
          <Box className="contact-image">
            <img
              src={contact}
              alt="Contact Illustration"
              className="contact-img"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
