import React, { useEffect, useRef, useState } from "react";
import "./LandingUseCase.css";
import AIML from "../LandingPageImages/AIML.png";

function AIMLSection() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry);
        if (entry.isIntersecting) {
          console.log("Element is visible!");
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="aiml-split-outer">
      <div
        ref={sectionRef}
        className={`cad-split-screen ${isVisible ? "fade-in" : ""}`}
      >
        {/* Left Side: Content */}
        <div className="cad-left">
          <div className="cad-content">
            <h1>
              Unlock Innovation: AI/ML Solutions for Data-Driven Decision Making
            </h1>
            <p>
              Our AI and Machine Learning services empower your business to
              harness the power of data, automating processes, enhancing
              predictions, and driving actionable insights. From building
              predictive models to implementing intelligent automation, we
              provide custom solutions that accelerate your digital
              transformation.
            </p>
            <ul>
              <li>Predictive Analytics for Market Trends</li>
              <li>Automated Data Processing and Analysis</li>
              <li>AI-Powered Customer Behavior Insights</li>
              <li>Integration with IoT and Cloud Platforms</li>
            </ul>
            <a href="/aiml" className="cad-button">
              EXPLORE MORE
            </a>
          </div>
        </div>

        {/* Right Side: Image */}
        <div className="aiml-right">
          <img src={AIML} alt="CAD Automation" />
        </div>
      </div>
    </div>
  );
}

export default AIMLSection;
