import React, { useEffect, useRef, useState } from "react";
import "./LandingUseCase.css";
import Fullstack from "../LandingPageImages/Fullstack1.png";

function FullStackSection() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry);
        if (entry.isIntersecting) {
          console.log("Element is visible!");
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="Fullstack-split-outer">
      <div
        ref={sectionRef}
        className={`cad-split-screen ${isVisible ? "fade-in" : ""}`}
      >
        {/* Left Side: Content */}
        <div className="cad-left">
          <div className="cad-content">
            <h1>
              Empowering Full Stack Development: End-to-End Web and Application
              Solutions
            </h1>
            <p>
              Our Full Stack development services offer comprehensive solutions
              for building dynamic and scalable web and mobile applications.
              From frontend interfaces to backend infrastructure, we ensure
              seamless integration and performance.
            </p>
            <ul>
              <li>Responsive Frontend Development</li>
              <li>Robust Backend Architecture</li>
              <li>API Integration and Development</li>
              <li>Database Design and Optimization</li>
            </ul>

            <a href="/fullstack" className="cad-button">
              EXPLORE MORE
            </a>
          </div>
        </div>

        {/* Right Side: Image */}
        <div className="cad-right">
          <img src={Fullstack} alt="CAD Automation" />
        </div>
      </div>
    </div>
  );
}

export default FullStackSection;
