import React, { useState } from "react";
import ".././GeneralTemplate/GeneralTemplate.css";
import TitleProps from "../GeneralTemplate/TitleProps";
import DigitalDescription from "./DigitalMarketInfo";
import DigitalMarket from "./DigitalMarketImages/DM4.jpg";
import Header1 from "./../Header/Header1";
import FooterMain from "./../Footer/FooterMain";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import SmallSnackBar from "../SmallSnackBar/SmallSnackBar";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import GetInTouch from "../GeneralTemplate/GetInTouch";
import EnquiryModal from "../ModelBox/ModelBox";

const Title2 = [
  {
    imageSrc: DigitalMarket,
    title: "Digital Marketing",
    description:
      "Digital marketing enhances brand visibility and engagement through targeted strategies, leveraging SEO, social media, and data analytics for maximum impact.",
    buttonLabel: "Reach Out",
  },
];

const Section1Data = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Services: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      Phone: value,
    }));
  };

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/reach_out`, formData);
      console.log("Data submitted successfully:", response.data);
      customAlert("", "Form submitted successfully", "success");

      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Services: "",
        Message: "",
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error submitting data:", error);
      customAlert("", "Failed to submit the form", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallSnackBar />
      <Header1 />
      <div className="TitleProps-container">
        {Title2.map((Section1) => (
          <TitleProps
            key={Section1.title}
            imageSrc={Section1.imageSrc}
            title={Section1.title}
            description={Section1.description}
            buttonLabel={Section1.buttonLabel}
            onButtonClick={handleOpenModal}
          />
        ))}
        <DigitalDescription />
      </div>
      <GetInTouch />
      <FooterMain />
      <WhatsAppButton />
      <ScrollToTop />

      <EnquiryModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handlePhoneChange={handlePhoneChange}
        errors={errors}
      />

      {loading && (
        <ProcessProgressCircle RequiredProgressName="Submitting your application" />
      )}
    </>
  );
};

export default Section1Data;
