import React from "react";
import { Helmet } from "react-helmet";

function SEOComponnet(url) {
  const defaultTitle = " Happy Technovation ";
  const defaultDescription =
    "Join our expert-led training programs in CAD automation, AI/ML, Full Stack Development, Computer Vision, Large Language Models, Data Science, and Generative AI. Specializing in CATIA, NX, CREO, Autodesk, Solid Edge, Windchill, Teamcenter, 3DEXPERIENCE, Enovia, TensorFlow, PyTorch, and modern web technologies, we offer comprehensive courses to enhance your engineering and technological skills, productivity, and innovation.";
  const defaultKeywords = `
    Automotive Design, 
    AI/ML, 
    Artificial Intelligence, 
    Machine Learning, 
    CAD Automation, 
    Computer Vision, 
    Full Stack Development, 
    PLM Solutions, 
    Product Lifecycle Management, 
    Happy Technovation, 
    technology solutions, 
    software development, 
    automotive engineering, 
    industrial design, 
    custom CAD solutions, 
    AI-powered systems, 
    machine learning algorithms, 
    deep learning solutions, 
    autonomous systems, 
    intelligent automation, 
    digital twin technology, 
    industrial automation, 
    computer-aided design, 
    CAD customization, 
    AI in automotive, 
    software innovation, 
    AI-based product design, 
    computer vision applications, 
    robotics vision systems, 
    cloud-based PLM, 
    backend development, 
    frontend development, 
    cloud solutions, 
    web development, 
    mobile app development, 
    enterprise solutions, 
    AI in manufacturing, 
    AI in healthcare, 
    full stack developer, 
    JavaScript frameworks, 
    Python development, 
    digital transformation, 
    software consulting, 
    engineering services, 
    product design services, 
    digital innovation, 
    machine learning consulting, 
    AI consulting, AI for industry, 
    automated design processes, 
    3D CAD software, 
    PLM software solutions, 
    big data solutions, 
    SaaS development, 
    cloud infrastructure, 
    system integration, 
    DevOps solutions, 
    digital engineering, 
    artificial intelligence services, 
    AI product development, 
    CAD tools for engineering, 
    AI-driven insights, 
    PLM strategy, 
    digital supply chain, 
    innovation in automotive design, 
    enterprise software development, 
    automation technology, 
    software as a service, 
    cloud computing, 
    CAD software integration.
`;
  const defaultUrl = "https://happytechnovation.com/";

  return (
    <Helmet>
      <title>{defaultTitle}</title>
      <meta name="description" content={defaultDescription} />
      <meta name="keywords" content={defaultKeywords} />
      <link rel="canonical" href={url || defaultUrl} />
      <script type="application/ld+json">
        {`
                {
                    "@context": "https://schema.org",
                    "@type": [
                        "Organization",
                        "Corporation",
                        "TechnologyCompany",
                        "Service",
                        "ConsultingService",
                        "SoftwareApplication",
                        "ProfessionalService",
                        "ResearchOrganization",
                        "EngineeringTrainingHub"
                    ],
                    "name": "Happy Technovation",
                    "alternateName": [
                        "Happy Technovation Solutions",
                        "HT Tech Solutions",
                        "HT Software Innovations",
                        "Happy Tech Services",
                        "HT CAD & AI Solutions",
                        "Happy Technovation Technologies",
                        "HT Engineering Solutions",
                        "HT AI and Automation",
                        "HT Full Stack Services",
                        "HT Automotive & PLM Solutions",
                        "HT AI/ML & CAD Customization",
                        "HT Software and Consulting",
                        "HT AI-Powered Innovations",
                        "Happy Tech Innovations"
                    ],
                    "url": "${url || defaultUrl}",
                    "logo": "https://happytechnovation.com/wp-content/uploads/2022/01/HT_LOGO_Final-2048x911.png",
                    "sameAs": [
                        "https://www.facebook.com/your-profile",
                        "https://www.linkedin.com/in/happy-technovation/"
                    ]
                }
                `}
      </script>
    </Helmet>
  );
}

export default SEOComponnet;
