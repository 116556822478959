import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./progressbar.css";

function ProcessProgressCircle({ RequiredProgressName }) {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (!showLoader) {
    return null;
  }

  return (
    <div className="process-progress-circle-container">
      <div className="process-progress-circle-background-color">
        <div className="progress-bar-alignment">
          <React.Fragment>
            <svg width={0} height={0}>
              <defs>
                <linearGradient
                  id="my_gradient"
                  x1="0%"
                  y1="0%"
                  x2="0%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#e01cd5" />
                  <stop offset="100%" stopColor="#1CB5E0" />
                </linearGradient>
              </defs>
            </svg>
            <CircularProgress
              sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
            />
          </React.Fragment>
        </div>
        <div className="text-conataienalignment-progrees-bar">
          {RequiredProgressName} Please wait
        </div>
      </div>
    </div>
  );
}

export default ProcessProgressCircle;
